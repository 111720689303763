import React, { Component } from 'react'
import { Button, Modal, OverlayTrigger, Tooltip, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/pro-duotone-svg-icons/faPenToSquare'
import MultiUpdateContentDossier from '../../../../feature/ContentDossier/Create/MultiUpdateContentDossier.tsx'

class MultiContentDossierMassUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            updateDefDossierForm: false,
            overwrite: false,
        }

        this.handleClose = this.handleClose.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
    }

    handleClose() {
        this.setState({ updateDefDossierForm: false })
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    handleOnChange(e) {
        this.setState({ overwrite: e.target.value === 'true' })
    }

    overwrite() {
        const kedo = this.props.kedo
        return (
            <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Control
                    as="select"
                    onChange={(e) => this.handleOnChange(e)}
                >
                    <option value={'false'}>
                        {kedo.t("Don't overwrite (Standard)")}
                    </option>
                    <option value={'true'}>{kedo.t('Overwrite')}</option>
                </Form.Control>
            </Form.Group>
        )
    }

    formUpdate() {
        const kedo = this.props.kedo

        return (
            <Modal
                key={`inline-modal-${this.props.dossierId}`}
                centered
                backdrop="static"
                show={true}
                onHide={() => {
                    this.handleClose ? this.handleClose() : null
                }}
                size={'xl'}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{kedo.t('Mass Update')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>{kedo.t('Options')}</h6>
                    <hr />
                    <p>{kedo.t('Fields that already have a value')}</p>
                    {this.overwrite()}
                    <MultiUpdateContentDossier
                        kedo={this.props.kedo}
                        props={{
                            multiUpdate: true,
                            overwrite: this.state.overwrite,
                            selectedDossierIds:
                                this.props.selectedDossierIds.map(
                                    (selectedIds) => selectedIds
                                ),
                            onClose: this.handleClose,
                        }}
                    />
                </Modal.Body>
            </Modal>
        )
    }

    displayButton(onClick, button, tooltip = null) {
        if (!tooltip)
            return (
                <Button variant={'outline-secondary'} onClick={onClick}>
                    {button}
                </Button>
            )

        return (
            <>
                <OverlayTrigger
                    placement={'top'}
                    overlay={<Tooltip>{tooltip}</Tooltip>}
                >
                    <Button variant={'outline-secondary'} onClick={onClick}>
                        {button}
                    </Button>
                </OverlayTrigger>
            </>
        )
    }

    render() {
        if (this.state.updateDefDossierForm || this.props.showModal) {
            return this.formUpdate()
        }

        const mode = this.props.mode
        return (
            <span>
                {this.displayButton(
                    () =>
                        this.setState({
                            updateDefDossierForm: true,
                        }),
                    <>
                        <FontAwesomeIcon icon={faPenToSquare} />{' '}
                        {mode &&
                        (mode === 'embeddedList' || mode === 'block') ? null : (
                            <>&nbsp; {this.props.kedo.t('Mass update')}</>
                        )}
                    </>,
                    mode && (mode === 'embeddedList' || mode === 'block')
                        ? this.props.kedo.t('Mass update')
                        : null
                )}
            </span>
        )
    }
}

export default MultiContentDossierMassUpdate
