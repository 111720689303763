import * as React from 'react'
import { createRef, RefObject, useEffect, useRef, useState } from 'react'
import {
    default as ContentDossierIndex,
    ContentDossierIndexHandle,
} from '../ContentDossier/Index/ContentDossierIndex'
import {
    Alert,
    Button,
    ButtonGroup,
    Dropdown,
    DropdownButton,
    Modal,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/pro-duotone-svg-icons/faCalendar'
import { faClipboardList } from '@fortawesome/pro-duotone-svg-icons/faClipboardList'
import { faFilter } from '@fortawesome/pro-duotone-svg-icons/faFilter'
import { faList } from '@fortawesome/pro-duotone-svg-icons/faList'
import { faPlus } from '@fortawesome/pro-duotone-svg-icons/faPlus'
import { faTasks } from '@fortawesome/pro-duotone-svg-icons/faTasks'

import LoadingDefault from '../../Components/Elements/Loading/LoadingDefault.jsx'
import SearchDefDossier from '../../Components/Pages/DefDossier/SearchDefDossier.jsx'
import { toast } from 'react-toastify'
import { ToastOptions } from 'react-toastify/dist/types'
import DefDossierKanban from '../../Components/Pages/DefDossier/DefDossierKanban.jsx'
import DefDossierTimeline from '../../Components/Pages/DefDossier/DefDossierTimeline.jsx'
import DefDossierGanttChart from '../../Components/Pages/DefDossier/DefDossierGanttChart.jsx'
import DefDossierCalender from '../../Components/Pages/DefDossier/DefDossierCalender.jsx'
import { useSocketService } from '../../context/SocketServiceContext'
import CreateContentDossier from '../ContentDossier/Create/CreateContentDossier'
import Loading from '../../Components/Elements/Loading/LoadingData.jsx'
import ContentDossier from '../ContentDossier/Detail/ContentDossier'
import { faRotate } from '@fortawesome/pro-duotone-svg-icons/faRotate'

const EmbeddedLinkField = (props) => {
    const socketService = useSocketService()
    let curRef = useRef<ContentDossierIndexHandle>(null)
    const [result, setResult] = useState([])
    const [addDossier, setAddDossier] = useState(false)
    const [isRefreshing, setIsRefreshing] = useState(false)
    const [refreshInitialed, setRefreshInitialized] = useState(false)
    const [localView, setLocalView] = useState(
        props.item && props.item.settings?.views?.length === 1
            ? props.item.settings.views[0]
            : 'list'
    )
    const [showEmail, setShowEmail] = useState(
        props.item &&
            props.item.settings &&
            props.item &&
            props.item.settings.show_email === true
            ? true
            : false
    )

    const [dataRefresh, setDataRefresh] = useState(
        props.item &&
            props.item.settings &&
            props.item &&
            props.item.settings.data_refresh === true
            ? true
            : false
    )

    const [hasRowInput, setHasRowInput] = useState(
        props.item &&
            props.item.settings &&
            props.item &&
            props.item.settings.row_input === true
            ? true
            : false
    )

    const [dossierQuery, setDossierQuery] = useState(null)
    const [search, setSearch] = useState(null)
    const [searchItems, setSearchItems] = useState([])
    const [searchItemsLoading, setSearchItemsLoading] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const [loading, setLoading] = useState(false)
    const [dpChildMain, setDpChildMain] = useState(null)
    const [multiAdd, setMultiAdd] = useState(false)
    const [pager, setPager] = useState({})
    const [dossierId, setDossierId] = useState<number>()

    const toastOptions = {
        position: 'bottom-center',
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    } as ToastOptions

    const getCurrentRef = (): RefObject<any> => {
        if (!curRef) {
            curRef = React.createRef()
        }

        return curRef
    }

    if (dataRefresh === true && refreshInitialed === false) {
        setRefreshInitialized(true)

        setTimeout(async () => {
            setInterval(async () => {
                if (curRef && curRef.current) {
                    await curRef.current.refreshResults(true)
                }
            }, 10000)
        }, 500)
    }

    const onSuccess = (dossierId) => {
        setTimeout(() => {
            socketService.displayItem.update({
                dossierId: dossierId,
                sender: socketService.socket()?.id,
                displayItem: props.item.id,
            })

            if (curRef && curRef.current) {
                curRef.current.fetchNewDossier(dossierId)
            }
        }, 100)
        setAddDossier(false)
    }

    const loadSocketCallbacks = () => {
        socketService.displayItem.refreshListener((data) => {
            if (
                socketService.socket().id !== data.sender.id &&
                data.displayItem === props.item.id
            ) {
                if (curRef && curRef.current) {
                    curRef.current.fetchNewDossier(data.dossierId)
                }

                if (props.kedo.user().getUserId() !== data.sender.userId) {
                    toast.success(
                        props.kedo.t('dossier_errors.success_create_ws', {
                            username: data.sender.username,
                        }),
                        toastOptions
                    )
                }
            }
        })
    }

    useEffect(() => {
        if (props.item?.settings?.embedded_single_view === true) {
            fetchOtherDisplayPositions()
        }

        return () => {
            socketService.displayItem.removeDdiListeners()
        }
    }, [])

    const isShowMultiAdd = () => {
        const settings = props.item.settings
        if (
            settings &&
            settings.show_multi_add &&
            settings.show_multi_add === true
        ) {
            return true
        }

        return false
    }

    const isHideAdd = () => {
        const settings = props.item.settings
        if (settings && settings.hide_add && settings.hide_add === true) {
            return true
        }

        return false
    }

    const fetchSearchFilterItems = async () => {
        const api = props.kedo.api()
        const params = {
            params: {
                view: ['search', 'advanced-search'],
                defDossier: props.item.def_dossier_link.child_def_dossier_id,
            },
        }

        setSearchItemsLoading(true)

        await api
            .getCached(api.getDisplayItemEndpoint(), params)
            .then((response) => {
                setSearchItemsLoading(false)
                setSearchItems(response.data.results)
            })
    }

    const renderAddDossier = () => {
        const kedo = props.kedo
        let defDossier = null
        if (
            props.item.def_dossier_link &&
            props.item.def_dossier_link.child_def_dossier_id
        ) {
            defDossier = props.kedo
                .env()
                .getDefDossierById(
                    props.item.def_dossier_link.child_def_dossier_id
                )
        }

        return (
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                show={true}
                size="xl"
                onHide={() => setAddDossier(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {kedo.t('New')}{' '}
                        {defDossier
                            ? kedo.env().translateItem(defDossier, 'defdossier')
                            : kedo.t('Dossier').toLowerCase()}
                        {props.item.def_dossier_link.child_def_dossier_name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateContentDossier
                        kedo={kedo}
                        props={{
                            ...props,
                            multiAdd: multiAdd,
                            defDossierId:
                                props.item.def_dossier_link
                                    .child_def_dossier_id,
                            embeddedDefDossierId: props.item.def_dossier_id,
                            leftSideBar:
                                props.item.settings &&
                                props.item.settings.passing_sidebar
                                    ? props.item.settings.passing_sidebar
                                    : null,
                            embedded: props.dossier.id,
                            embeddedFieldId: props.item.id,
                            linkId: props.item.def_dossier_link.back_link_id,
                            location: 'EmbeddedLinkField',
                            onClose: () => setAddDossier(false),
                            onSuccess: onSuccess,
                        }}
                    />
                </Modal.Body>
            </Modal>
        )
    }

    useEffect(() => {
        if (curRef && curRef.current) {
            if (search || dossierQuery)
                curRef.current.doSearch(search, dossierQuery)
        }
    }, [search, dossierQuery])

    const onChangeLocalView = (view) => {
        setLocalView(view)
    }

    const doSearch = (values, dossierQuery) => {
        setSearch(values)
        setDossierQuery(dossierQuery)
    }

    const getFilterButton = () => {
        return (
            <Button
                onClick={() => {
                    if (!showFilter) {
                        fetchSearchFilterItems()
                    }
                    setShowFilter((prevState) => {
                        return !prevState
                    })
                }}
                variant="primary"
            >
                {searchItemsLoading ? (
                    <LoadingDefault />
                ) : (
                    <FontAwesomeIcon icon={faFilter} size="xs" />
                )}
            </Button>
        )
    }

    const getViews = () => {
        if (
            !props.item.settings ||
            !props.item.settings.views ||
            !Array.isArray(props.item.settings.views)
        ) {
            return ['list']
        }

        return props.item.settings.views
    }

    const renderShow = () => {
        const kedo = props.kedo

        const isAllowedToAdd = () => {
            return kedo
                .env()
                .isAllowedDefDossier(
                    'create',
                    props.item.def_dossier_link.child_def_dossier_id,
                    kedo.user()
                )
        }

        const views = getViews()
        let viewIcon = faList

        if (localView === 'calendar') {
            viewIcon = faCalendar
        } else if (localView === 'kanban') {
            viewIcon = faTasks
        } else if (localView === 'gantt') {
            viewIcon = faClipboardList
        }

        return (
            <>
                {views.length > 1 ? (
                    <DropdownButton
                        as={ButtonGroup}
                        title={<FontAwesomeIcon icon={viewIcon} />}
                    >
                        {views.includes('list') || views.length <= 0 ? (
                            <Dropdown.Item
                                eventKey="1"
                                onClick={() => onChangeLocalView('list')}
                                value={'list'}
                            >
                                <FontAwesomeIcon icon={faList} />{' '}
                                {kedo.t('List')}
                            </Dropdown.Item>
                        ) : null}
                        {views.includes('calendar') || views.length <= 0 ? (
                            <Dropdown.Item
                                eventKey="1"
                                onClick={() => onChangeLocalView('calendar')}
                                value={'calendar'}
                            >
                                <FontAwesomeIcon icon={faCalendar} />{' '}
                                {kedo.t('Calendar')}
                            </Dropdown.Item>
                        ) : null}
                        {views.includes('kanban') || views.length <= 0 ? (
                            <Dropdown.Item
                                eventKey="1"
                                onClick={() => onChangeLocalView('kanban')}
                                value={'kanban'}
                            >
                                <FontAwesomeIcon icon={faTasks} />{' '}
                                {kedo.t('Kanban')}
                            </Dropdown.Item>
                        ) : null}
                        {views.includes('timeline') || views.length <= 0 ? (
                            <Dropdown.Item
                                eventKey="1"
                                onClick={() => onChangeLocalView('timeline')}
                                value={'timeline'}
                            >
                                <FontAwesomeIcon icon={faClipboardList} />{' '}
                                {kedo.t('Timeline')}
                            </Dropdown.Item>
                        ) : null}
                        {views.includes('gantt') || views.length <= 0 ? (
                            <Dropdown.Item
                                eventKey="1"
                                onClick={() => onChangeLocalView('gantt')}
                                value={'gantt'}
                            >
                                <FontAwesomeIcon icon={faClipboardList} />{' '}
                                {kedo.t('Gantt Chart')}
                            </Dropdown.Item>
                        ) : null}
                        {(kedo.env().hasModule('hourregistration') &&
                            views.includes('hourregistration')) ||
                        views.length <= 0 ? (
                            <Dropdown.Item
                                eventKey="1"
                                onClick={() =>
                                    onChangeLocalView('hourregistration')
                                }
                                value={'hourregistration'}
                            >
                                <FontAwesomeIcon icon={faClipboardList} />{' '}
                                {kedo.t('Hourregistration')}
                            </Dropdown.Item>
                        ) : null}
                    </DropdownButton>
                ) : null}
                <div style={{ display: 'inline' }}>
                    {addDossier && isHideAdd() === false
                        ? renderAddDossier()
                        : null}
                    {showFilter ? renderFilter() : null}
                    {dataRefresh && isRefreshing ? (
                        <FontAwesomeIcon icon={faRotate} spin={isRefreshing} />
                    ) : null}
                </div>

                {localView === 'kanban' ? (
                    <DefDossierKanban
                        dossierQuery={dossierQuery}
                        ddiSort={
                            props.item && props.item.settings?.sort
                                ? props.item.settings?.sort
                                : null
                        }
                        embedded={props.dossier.id}
                        linkId={props.item.def_dossier_link.back_link_id}
                        defDossierId={
                            props.item.def_dossier_link.child_def_dossier_id
                        }
                        kedo={kedo}
                    />
                ) : null}
                {localView === 'timeline' ? (
                    <DefDossierTimeline
                        kedo={kedo}
                        defDossierId={
                            props.item.def_dossier_link.child_def_dossier_id
                        }
                        ddiSort={
                            props.item?.settings?.sort
                                ? props.item.settings?.sort
                                : null
                        }
                        dossierQuery={dossierQuery}
                        embedded={props.dossier ? props.dossier.id : null}
                        linkId={props.item.def_dossier_link.back_link_id}
                    />
                ) : null}
                {localView === 'calendar' ? (
                    <DefDossierCalender
                        kedo={kedo}
                        defDossierId={
                            props.item.def_dossier_link.child_def_dossier_id
                        }
                    />
                ) : null}
                {localView === 'gantt' ? (
                    <DefDossierGanttChart
                        kedo={kedo}
                        defDossierId={
                            props.item.def_dossier_link.child_def_dossier_id
                        }
                    />
                ) : null}
                {localView === 'list' ? (
                    <ContentDossierIndex
                        props={{
                            ...props,
                            defDossierId:
                                props.item.def_dossier_link
                                    .child_def_dossier_id,
                            dossierQuery: dossierQuery,
                            embedded: props.dossier ? props.dossier.id : null,
                            customView: props.item?.settings?.custom_view
                                ? props.item.settings.custom_view
                                : null,
                            filterDossier: getFilterButton(),
                            linkId: props.item.def_dossier_link.back_link_id,
                            mode: 'embeddedList',
                            searchParams: search,
                            ddiSort: props.item?.settings?.sort
                                ? props.item.settings?.sort
                                : null,
                            showEmail: showEmail,
                            onClick: (dossier) => {
                                window.location.href =
                                    '/contentdossier/' + dossier.id
                            },
                            addDossier:
                                isHideAdd() === false && isAllowedToAdd() ? (
                                    <Button
                                        onClick={() => setAddDossier(true)}
                                        variant="primary"
                                    >
                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            size="xs"
                                        />
                                    </Button>
                                ) : null,
                            rowInput: hasRowInput,
                            multiAdd:
                                isShowMultiAdd() && isAllowedToAdd() ? (
                                    <Button
                                        className={'mr-2'}
                                        title={kedo.t('Multi add')}
                                        onClick={() => {
                                            setAddDossier(true)
                                            setMultiAdd(true)
                                        }}
                                        variant="primary"
                                    >
                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            className={'mr-1'}
                                            size="xs"
                                        />
                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            size="xs"
                                        />
                                    </Button>
                                ) : null,
                        }}
                        kedo={kedo}
                        forwardedRef={getCurrentRef()}
                    />
                ) : null}
            </>
        )
    }

    const isSingleSelect = () => {
        const settings = props.item.def_dossier_link.settings
        if (settings && settings.multiple && settings.multiple === true) {
            return false
        }

        return true
    }

    const renderEmbeddedView = () => {
        const kedo = props.kedo

        if (!isSingleSelect()) {
            return (
                <Alert variant={'warning'}>
                    {props.kedo.t('A embedded view can only contain one link')}
                </Alert>
            )
        }

        if (!dpChildMain) {
            return
        }

        if (props.value.length > 1) {
            return (
                <Alert variant={'warning'}>
                    {kedo.t(
                        'You cannot convert a multiselect to an embedded view'
                    )}
                </Alert>
            )
        } else if (props.value.length === 1 || dossierId) {
            const currentDossierId = dossierId || props.value[0].id
            return (
                <ContentDossier
                    props={{
                        //setEdit: this.props.mode === 'edit' || this.props.mode === 'show/edit' ? this.state.dpChildMain.id : false,
                        embeddedInlineSingle: true,
                        selectedTab: dpChildMain?.id,
                        existingUser: true,
                        showSaveClose: true,
                        modalized: true,
                        onClose: () => {
                            //Todo
                        },
                        dossierId: currentDossierId,
                        disableBreadcrumb: true,
                    }}
                    kedo={kedo}
                />
            )
        }

        if (!props.item?.def_dossier_link?.child_def_dossier_id) {
            return <span>Loading</span>
        }

        const allowedToAdd = props.kedo
            .env()
            .isAllowedDefDossier(
                'create',
                props.item.def_dossier_link.child_def_dossier_id
            )

        if (!allowedToAdd) {
            return <span>{props.kedo.t('No items found')}</span>
        }

        if (!dossierId && props.value.length === 0) {
            return (
                <CreateContentDossier
                    props={{
                        kedo: kedo,
                        multiAdd: multiAdd,
                        defDossierId:
                            props.item.def_dossier_link.child_def_dossier_id,
                        embeddedDefDossierId: props.item.def_dossier_id,
                        embedded: props.dossier.id,
                        embeddedFieldId: props.item.id,
                        linkId: props.item.def_dossier_link.back_link_id,
                        location: 'EmbeddedLinkField',
                        leftSideBar:
                            props.item.settings &&
                            props.item.settings.passing_sidebar
                                ? props.item.settings.passing_sidebar
                                : null,
                        onSuccess: (dosId: React.SetStateAction<number>) => {
                            setDossierId(dosId)
                            setLoading(true)
                            setTimeout(() => {
                                setLoading(false)
                            }, 500)
                        },
                        onClose: () => setAddDossier(false),
                    }}
                    kedo={props.kedo}
                />
            )
        }
    }

    const fetchOtherDisplayPositions = () => {
        const dpFetchUrl =
            props.kedo.api().getDisplayPositionEndpoint() +
            '?defDossier=' +
            props.item.def_dossier_link.child_def_dossier_id +
            '&limit=100'

        props.kedo
            .api()
            .getCached(dpFetchUrl)
            .then((response) => {
                const dpFetchMain = response.data.results.find(
                    (item) => item.type === 'main_content'
                )
                setDpChildMain(dpFetchMain)
            })
    }

    const getDefDossierFromId = (defDossierId) => {
        return props.kedo
            .env()
            .getCurrentEnvironmentDefDossiers()
            .find((item) => item.id === parseInt(defDossierId))
    }

    const renderFilter = () => {
        const items = searchItems.filter(
            (searchItem) =>
                searchItem.view === 'search' ||
                searchItem.view === 'advanced-search'
        )

        return searchItemsLoading ? null : (
            <div>
                <br />
                <SearchDefDossier
                    doSearch={doSearch}
                    dossierQuery={dossierQuery}
                    kedo={props.kedo}
                    defDossier={getDefDossierFromId(
                        props.item.def_dossier_link.child_def_dossier_id
                    )}
                    items={items}
                    resetSearch={() => setSearch(null)}
                    search={search}
                    location={'EmbeddedLinkField'}
                />
            </div>
        )
    }

    const renderList = () => {
        if (!props.value || props.value.length <= 0 || !props.value.map) {
            return ''
        }

        return (
            <ul>
                {props.value.map((item) => (
                    <li key={item.id}>
                        <Link to={'/contentdossier/' + item.id}>
                            {item.summary}
                        </Link>
                    </li>
                ))}
            </ul>
        )
    }

    if (!props.dossier || !props.dossier.id) {
        return ''
    }

    if (loading === true) {
        return <Loading kedo={props.kedo} />
    }

    if (props.item?.settings?.embedded_single_view === true) {
        return renderEmbeddedView()
    }

    if (props.mode === 'list') {
        return renderList()
    }

    return renderShow()
}

export default EmbeddedLinkField
