import * as React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { ConfirmationModalProps } from './useUnsavedChangesModal'

const ConfirmationdModal: React.FC<ConfirmationModalProps> = (props) => {
    return (
        <Modal centered show={true} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.content}</Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={props.onConfirm}>
                    {props.confirmBtnLabel}
                </Button>
                <Button variant="secondary" onClick={props.onClose}>
                    {props.closeBtnLabel}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmationdModal
