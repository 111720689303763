import React from 'react'

class BlockLazy extends React.Component {
    constructor(props) {
        super(props)
        this.blockRef = React.createRef()
        this.observer = null
        this.state = {
            isInViewPort: false,
            timeoutId: null,
        }
    }
    componentDidMount() {
        this.observer = new IntersectionObserver(this.handleIntersection, {
            rootMargin: '100px',
            threshold: 0.5,
        })
        const timeoutId = setTimeout(() => {
            const blockElement = this.blockRef.current
            if (blockElement) {
                this.observer.observe(blockElement)
            } else {
                console.warn(
                    'blockRef.current not available at the time of viewing.'
                )
            }
        }, 1000) // add a delay of 1 second

        this.setState({ timeoutId })
    }

    handleIntersection = (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                this.setState({ isInViewPort: true })
                clearTimeout(this.state.timeoutId)
                this.observer.unobserve(this.blockRef.current)
            }
        })
    }
    render() {
        return (
            <div ref={this.blockRef} {...this.props} className="onScrollHandle">
                {this.state.isInViewPort ? this.props.children : null}
            </div>
        )
    }
}

export default BlockLazy
