import React, { Component, forwardRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/pro-duotone-svg-icons/faEdit'
import { Button, Modal } from 'react-bootstrap'
import DetailContentDossier from '../../feature/ContentDossier/Detail/ContentDossier.tsx'

class DossierModalEditButton extends Component {
    constructor(props) {
        super(props)

        this.state = {
            edit: this.props.edit === true,
            size: this.props.size ? this.props.size : 'sm',
            showSaveAndClose: this.props.showSaveAndClose !== false,
        }
        this.renderEdit = this.renderEdit.bind(this)
        this.renderTitle = this.renderTitle.bind(this)
        //this.ref = React.createRef();
    }

    renderTitle() {
        if (!this.state.title) {
            return
        }

        return this.state.title()
    }

    renderEdit() {
        return (
            <Modal
                key={`inline-modal-${this.props.dossierId}`}
                centered
                backdrop="static"
                show={true}
                onHide={() => {
                    this.props.onClose ? this.props.onClose() : null
                    this.setState({ edit: false })
                }}
                size={'xl'}
            >
                <Modal.Header closeButton>{this.renderTitle()}</Modal.Header>
                <Modal.Body>
                    <DetailContentDossier
                        props={{
                            embeddedInlineSingle: true,
                            setTitle: (funca) =>
                                this.setState({ title: funca }),
                            modalized: true,
                            dossierId: this.props.dossierId,
                            onDeleteSuccess: this.props.onDeleteSuccess,
                            onSuccess:
                                this.state.showSaveAndClose === true
                                    ? (closeIt) => {
                                          if (this.props.onSuccess) {
                                              this.props.onSuccess(closeIt)
                                          }
                                      }
                                    : this.props.onSuccess,
                            showSaveClose: this.state.showSaveAndClose,
                            onClose: this.props.onClose
                                ? this.props.onClose
                                : () => this.setState({ edit: false }),
                            embedded: this.props.embedded,
                            disableBreadcrumb: true,
                            quickEdit: this.props.quickEdit,
                            quickShow: this.props.quickShow,
                            handlePermissions: this.props.handlePermissions,
                        }}
                        kedo={this.props.kedo}
                    />
                </Modal.Body>
            </Modal>
        )
    }

    componentDidMount() {
        if (this.props.kedo.socket().getSocket()) {
            this.props.kedo.socket().getSocket().emit('dossier:exit')
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.edit !== this.state.edit) {
            return true
        }

        return false
    }

    render() {
        if (!this.props.dossierId) {
            return null
        }

        return (
            <div>
                {this.state.edit ? this.renderEdit() : null}
                <Button
                    className={'float-right dossier-inline-edit'}
                    onClick={() => this.setState({ edit: true })}
                    size={this.state.size}
                >
                    <FontAwesomeIcon icon={faEdit} />
                </Button>
            </div>
        )
    }
}

export default DossierModalEditButton
