import React from 'react'
import { Modal } from 'react-bootstrap'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import LoginModal from '../LoginModal'

const IdleTimeoutPrompt = (props) => {
    const renderTime = (remainingTime, kedo) => {
        return (
            <div className="timer">
                <div className="text">{kedo.t('Remaining')}</div>
                <div className="value">{remainingTime}</div>
                <div className="text">{kedo.t('seconds')}</div>
            </div>
        )
    }

    const duration = Math.floor(props.promptTimeout / 1000)

    return (
        <Modal
            size={'sm'}
            aria-labelledby="contained-modal-title-vcenter"
            backdropClassName={'inactive-backdrop'}
            className={'modal-inactive inactivePopUp'}
            centered
            show={true}
            style={{ zIndex: '3000' }}
        >
            <Modal.Body>
                <div className={'timer-wrapper'}>
                    <CountdownCircleTimer
                        isPlaying
                        duration={duration}
                        colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                        colorsTime={[120, 60, 30, 0]}
                    >
                        {({ remainingTime }) =>
                            renderTime(remainingTime, props.kedo)
                        }
                    </CountdownCircleTimer>
                </div>
                <div className={'timer-msg text-center'}>
                    {props.kedo.t('Inactivity detected')}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default IdleTimeoutPrompt
