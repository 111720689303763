import React, { Component, useEffect, useState } from 'react'
import { Switch, Redirect, Route, useLocation } from 'react-router-dom'
import Api from './Api'
import Home from './Components/Home'
import EnvironmentIndex from './feature/Environment/EnvironmentList/EnvironmentIndex'
import DefaultPage from './Components/Template/DefaultPage'
import User from './User'
import ForgotPassword from './Components/ForgotPassword'
import AzureConnect from './Components/AzureConnect'
import TwoFactorAuthentication from './Components/auth/TwoFactorAuthentication'
import NotFound from './Components/Pages/NotFound'
import { ToastContainer } from 'react-toastify'
import { useSocketService } from './context/SocketServiceContext.tsx'
import Timeout from './Components/IdleTimer/Timeout.tsx'

const handleLogin = (data, callBack = null, idleTimer = null, setBgBlur) => {
    const base64Url = data.token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    const tokenData = JSON.parse(window.atob(base64))
    var user = new User()
    var api = new Api()
    user.updateInfoFromJWTToken(tokenData)

    if (idleTimer) {
        idleTimer()
    }

    if (callBack) callBack(true, user)

    api.updateToken(tokenData, data.token, data.refresh_token)
    if (typeof setBgBlur === 'function') setBgBlur(false)
}

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            api: new Api(),
            idleTimer: this.props.idleTimer,
        }

        this.handleLogout = this.handleLogout.bind(this)
    }

    handleLogout() {
        this.setState({
            loggedIn: false,
            user: {},
        })
        const data = JSON.parse(localStorage.getItem('tokenData'))
        const lastVisit = new Date(parseInt(data.iat) * 1000)
        localStorage.setItem('lastVisit', lastVisit.getTime())
        localStorage.clear()
    }

    render() {
        return (
            <Home
                kedo={this.props.kedo}
                {...this.props}
                api={this.state.api}
                handleLogin={(data) =>
                    handleLogin(data, (loggedIn, user) => {
                        this.setState({
                            loggedIn: loggedIn,
                            user: user,
                        })
                    })
                }
                handleLogout={this.handleLogout}
            />
        )
    }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            rest.kedo.user().isLoggedIn() === true ? (
                <Component kedo={rest.kedo} {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
)

const App = ({ user, kedo, i18n }) => {
    const location = useLocation()
    const socketService = useSocketService()

    const [loggedIn, setLoggedIn] = useState(false)
    const [bgBlur, setBgBlur] = useState(false)
    const loggedInHandle = setInterval(() => {
        if (kedo.user().isLoggedIn()) {
            setLoggedIn(kedo.user().isLoggedIn())
            clearInterval(loggedInHandle)
        }
    }, 500)

    useEffect(() => {
        if (location.pathname !== '/') kedo.title().reset()
    }, [location.pathname])

    useEffect(() => {
        socketService.init({
            username: kedo.user().getUsername(),
            userId: kedo.user().getUserId(),
            environmentId: kedo.env().hasEnviroment()
                ? kedo.env().getEnvironmentId()
                : null,
        })
    }, [loggedIn])

    return (
        <div className={`app ${bgBlur ? 'inactive-modal-open' : ''}`}>
            <Timeout
                toggleBlur={{
                    enable: () => setBgBlur(true),
                    disable: () => setBgBlur(false),
                }}
                handleLogin={handleLogin}
                kedo={kedo}
            />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
            />

            <Switch>
                <Route kedo={kedo} exact path={'/login'} component={Login} />
                <Route
                    kedo={kedo}
                    exact
                    path={'/azure'}
                    component={AzureConnect}
                />
                <Route
                    kedo={kedo}
                    exact
                    path={'/authentication'}
                    component={TwoFactorAuthentication}
                />

                <Route
                    kedo={kedo}
                    exact
                    path={'/forgot_password'}
                    component={ForgotPassword}
                />

                <PrivateRoute
                    kedo={kedo}
                    path={[
                        '/colleagues',
                        '/contact',
                        '/contentdossier/create/:id',
                        '/contentdossier/:id',
                        '/datastructure/:id',
                        '/datastructure',
                        '/subscriptions',
                        '/events',
                        '/defdossier/conditions/:id',
                        '/defdossier/edit/:id',
                        '/defdossier/:id',
                        '/fieldeditor/:id',
                        '/erd',
                        '/import/:id',
                        '/files',
                        '/languages',
                        '/layout',
                        '/environment/edit',
                        '/messages/:id',
                        '/messages',
                        '/modules',
                        '/order/:id',
                        '/permissions',
                        '/profile',
                        '/security',
                        '/blocks/:id',
                        '/blocks',
                        '/querybuilder/:id',
                        '/querybuilder',
                        '/reports',
                        '/reports/overview',
                        '/role/:id',
                        '/roles',
                        '/upgrade',
                        '/user/:id',
                        '/user',
                        '/templates/new',
                        '/templates/:id',
                        '/templates',
                        '/logs',
                        '/useractivity',
                        '/webhook/:id',
                        '/webhooks',
                        '/module/jwwmo/toewijzingen',
                        '/wordkedocx/:id',
                        '/wordkedocx',
                    ]}
                    component={DefaultPage}
                />

                <PrivateRoute
                    kedo={kedo}
                    exact
                    path={'/environment'}
                    component={EnvironmentIndex}
                />
                <PrivateRoute
                    kedo={kedo}
                    exact
                    path={'/'}
                    component={DefaultPage}
                />
                <Route kedo={kedo} path={'*'} component={NotFound} />
            </Switch>
        </div>
    )
}

export default App
