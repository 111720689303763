import React from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChartLine,
    faChevronCircleUp,
    faChevronCircleDown,
    faCogs,
} from '@fortawesome/pro-duotone-svg-icons'

class AdminMainMenu extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            displayAdminSettings: false,
        }

        this.handleAdminSettings = this.handleAdminSettings.bind(this)
    }

    handleAdminSettings() {
        this.setState((state) => ({
            displayAdminSettings: !state.displayAdminSettings,
        }))
    }

    render() {
        const kedo = this.props.kedo

        return (
            <nav className="dropdown MainMenu" aria-label="Admin Navigation">
                <ul style={{ background: 'none', borderTop: 'none' }}>
                    <li
                        className={'menu-item avLinkStyle'}
                        onClick={this.props.close}
                    >
                        <NavLink
                            activeClassName="selectedLink"
                            className={'menuHeadItem dashboard navLinkStyle'}
                            key={'dashboard'}
                            exact
                            to={`/`}
                        >
                            <FontAwesomeIcon icon={faChartLine} /> &nbsp;{' '}
                            {kedo.t('Dashboard')}
                        </NavLink>
                    </li>
                </ul>
                {kedo.isAdminOrEnvironmentAdmin() ? (
                    <div
                        onClick={this.handleAdminSettings}
                        className={'menuHeadItem navLinkStyle admin'}
                    >
                        <FontAwesomeIcon icon={faCogs} />
                        &nbsp; {kedo.t('Admin settings')}
                        <FontAwesomeIcon
                            icon={
                                this.state.displayAdminSettings
                                    ? faChevronCircleUp
                                    : faChevronCircleDown
                            }
                            className={'dropArrow float-right'}
                        />
                    </div>
                ) : null}
                {this.state.displayAdminSettings ? (
                    <ul onClick={() => window.scrollTo(0, 0)}>
                        <li onClick={this.props.close}>
                            <NavLink
                                to={'/environment/edit'}
                                className={'MainMenuLink  navLinkStyle'}
                                activeClassName="selectedLink"
                            >
                                {kedo.t('Generic')}
                            </NavLink>
                        </li>
                        <li onClick={this.props.close}>
                            <NavLink
                                to={'/datastructure'}
                                className={'MainMenuLink  navLinkStyle'}
                                activeClassName="selectedLink"
                            >
                                {kedo.t('Datastructures')}
                            </NavLink>
                        </li>
                        <li onClick={this.props.close}>
                            <NavLink
                                to={'/erd'}
                                className={'MainMenuLink  navLinkStyle'}
                                activeClassName="selectedLink"
                            >
                                {kedo.t('ERD')}
                            </NavLink>
                        </li>
                        {/*<NavLink to={'/layout'} className={"MainMenuLink"} activeClassName="selectedLink">*/}
                        {/*    <li onClick={this.props.close}>{kedo.t('Layout')}</li>*/}
                        {/*</NavLink>*/}
                        <li onClick={this.props.close}>
                            <NavLink
                                to={'/roles'}
                                className={'MainMenuLink  navLinkStyle'}
                                activeClassName="selectedLink"
                            >
                                {kedo.t('Roles')}
                            </NavLink>
                        </li>
                        <li onClick={this.props.close}>
                            <NavLink
                                to={'/permissions'}
                                className={'MainMenuLink  navLinkStyle'}
                                activeClassName="selectedLink"
                            >
                                {kedo.t('Permissions')}
                            </NavLink>
                        </li>
                        <li onClick={this.props.close}>
                            <NavLink
                                to={'/logs'}
                                className={'MainMenuLink  navLinkStyle'}
                                activeClassName="selectedLink"
                            >
                                {kedo.t('Logs')}
                            </NavLink>
                        </li>
                        <li onClick={this.props.close}>
                            <NavLink
                                to={'/useractivity'}
                                className={'MainMenuLink  navLinkStyle'}
                                activeClassName="selectedLink"
                            >
                                {kedo.t('User activity')}
                            </NavLink>
                        </li>
                        <li onClick={this.props.close}>
                            <NavLink
                                to={'/files'}
                                className={'MainMenuLink  navLinkStyle'}
                                activeClassName="selectedLink"
                            >
                                {kedo.t('Files')}
                            </NavLink>
                        </li>
                        {/*<NavLink to={'/backups'} className={"MainMenuLink  navLinkStyle"} activeClassName="selectedLink">*/}
                        {/*    <li onClick={this.props.close}>{kedo.t('Backups')}</li>*/}
                        {/*</NavLink>*/}
                        <li onClick={this.props.close}>
                            <NavLink
                                to={'/user'}
                                className={'MainMenuLink  navLinkStyle'}
                                activeClassName="selectedLink"
                            >
                                {kedo.t('Users')}
                            </NavLink>
                        </li>
                        <li onClick={this.props.close}>
                            <NavLink
                                to={'/languages'}
                                className={'MainMenuLink  navLinkStyle'}
                                activeClassName="selectedLink"
                            >
                                {kedo.t('Languages')}
                            </NavLink>
                        </li>
                        <li onClick={this.props.close}>
                            <NavLink
                                to={'/blocks'}
                                className={'MainMenuLink  navLinkStyle'}
                                activeClassName="selectedLink"
                            >
                                {kedo.t('Blocks')}
                            </NavLink>
                        </li>
                        <li onClick={this.props.close}>
                            <NavLink
                                to={'/modules'}
                                className={'MainMenuLink  navLinkStyle'}
                                activeClassName="selectedLink"
                            >
                                {kedo.t('Modules')}
                            </NavLink>
                        </li>
                        <li onClick={this.props.close}>
                            <NavLink
                                to={'/events'}
                                className={'MainMenuLink  navLinkStyle'}
                                activeClassName="selectedLink"
                            >
                                {kedo.t('Events')}
                            </NavLink>
                        </li>
                        <li onClick={this.props.close}>
                            <NavLink
                                to={'/wordkedocx'}
                                className={'MainMenuLink  navLinkStyle'}
                                activeClassName="selectedLink"
                            >
                                {kedo.t('Kedocx')}
                            </NavLink>
                        </li>
                        <li onClick={this.props.close}>
                            <NavLink
                                to={'/querybuilder'}
                                className={'MainMenuLink  navLinkStyle'}
                                activeClassName="selectedLink"
                            >
                                {kedo.t('Querybuilder')}
                            </NavLink>
                        </li>
                        {/*<NavLink to={'/reports'} className={"MainMenuLink  navLinkStyle"} activeClassName="selectedLink">*/}
                        {/*    <li onClick={this.props.close}>Reports</li>*/}
                        {/*</NavLink>*/}
                        <li onClick={this.props.close}>
                            <NavLink
                                to={'/webhooks'}
                                className={'MainMenuLink  navLinkStyle'}
                                activeClassName="selectedLink"
                            >
                                {kedo.t('Webhooks')}
                            </NavLink>
                        </li>
                    </ul>
                ) : null}
            </nav>
        )
    }
}

export default AdminMainMenu
