import * as React from 'react'
import { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder } from '@fortawesome/pro-duotone-svg-icons/faFolder'

import LoadingDefault from '../../../Elements/Loading/LoadingDefault.jsx'

interface MultiContentDossierArchiveProps {
    checked: Array<any>
    archived: boolean
    showModal: boolean
    onClose: () => void
    kedo: any
    mode: string
}
const MultiContentDossierArchive: React.FC<MultiContentDossierArchiveProps> = ({
    checked,
    archived,
    showModal,
    onClose,
    kedo,
    mode,
}) => {
    const [archiving, setArchiving] = useState(false)
    const [dearchiving, setDearchiving] = useState(false)
    const [dossier, setDossier] = useState<{ archived?: boolean }>({})

    const archive = async () => {
        if (!checked.length) return

        setArchiving(true)
        let counter = 0

        for (const x of checked) {
            const url = `${kedo.api().getContentDossierEndpoint()}/${x}/archive`

            kedo.api()
                .get(url)
                .then(() => {
                    counter++
                    if (counter === checked.length) {
                        setArchiving(false)
                        setDossier({ archived: true })
                        onClose()
                    }
                })
        }
    }

    const dearchive = async () => {
        if (!checked.length) return

        setDearchiving(true)
        let counter = 0

        for (const x of checked) {
            const url = `${kedo
                .api()
                .getContentDossierEndpoint()}/${x}/dearchive`

            kedo.api()
                .get(url)
                .then(() => {
                    counter++
                    if (counter === checked.length) {
                        setDearchiving(false)
                        setDossier({ archived: false })
                        onClose()
                    }
                })
        }
    }

    useEffect(() => {
        if (showModal) {
            archived ? archive() : dearchive()
            onClose()
        }
    }, [showModal])

    const renderShow = () => {
        return (
            <>
                <Button
                    variant="outline-secondary"
                    onClick={archived ? dearchive : archive}
                    disabled={archiving || dearchiving}
                >
                    {archiving || dearchiving ? (
                        <LoadingDefault />
                    ) : (
                        <FontAwesomeIcon icon={faFolder} />
                    )}
                    &nbsp; {kedo.t(archived ? 'Dearchive' : 'Archive')}
                </Button>
            </>
        )
    }

    return renderShow()
}

export default MultiContentDossierArchive
