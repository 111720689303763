import React, { Component } from 'react'
import {
    Alert,
    Button,
    Form,
    Table,
    Badge,
    Dropdown,
    ButtonGroup,
} from 'react-bootstrap'
import LoadingData from '../../Elements/Loading/LoadingData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faBell } from '@fortawesome/pro-duotone-svg-icons/faBell'
import { faEdit } from '@fortawesome/pro-duotone-svg-icons/faEdit'
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle'
import { faSort } from '@fortawesome/pro-duotone-svg-icons/faSort'
import { faSortDown } from '@fortawesome/pro-duotone-svg-icons/faSortDown'
import { faSortUp } from '@fortawesome/pro-duotone-svg-icons/faSortUp'
import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes'
import { faTrash } from '@fortawesome/pro-duotone-svg-icons/faTrash'
import { faFileExport } from '@fortawesome/pro-duotone-svg-icons/faFileExport'
import { faBars } from '@fortawesome/pro-duotone-svg-icons'
import { faBellSlash } from '@fortawesome/pro-duotone-svg-icons/faBellSlash'
import { faFolder } from '@fortawesome/pro-duotone-svg-icons/faFolder'

import ItemCounter from '../../Elements/ItemCounter'
import MultiContentDossierSubscribe from './DefDossierToolbar/MultiContentDossierSubscribe'
import MultiContentDossierArchive from './DefDossierToolbar/MultiContentDossierArchive.tsx'
import ContentDossierExport from './DefDossierToolbar/ContentDossierExport'
import MultiContentDossierDelete from './DefDossierToolbar/MultiContentDossierDelete'
import Pagination from '../../Elements/Pagination'
import ShowEntries from '../../Elements/ShowEntries'
import DisplayItem from '../../DisplayItems/DisplayItem'
import ExpiredModal from '../Upgrade/ExpiredModal'
import SearchDefDossier from './SearchDefDossier'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import DossierModalEditButton from '../../Elements/DossierModalEditButton'
import ContentDossierSend from '../ContentDossier/ContentDossierToolbar/ContentDossierSend'
import AdminQuickEditMenu from '../../Elements/AdminQuickEditMenu'
import { Link } from 'react-router-dom'

class DefDossierList extends Component {
    constructor(props) {
        super(props)
        this.ddListRef = React.createRef()

        let sortOptions = []
        if (this.props.ddiSort) {
            for (let i = 0; i < this.props.ddiSort.length; i++) {
                sortOptions.push({
                    order: this.props.ddiSort[i].sort,
                    item: {
                        def_dossier_def_field: {
                            id: parseInt(this.props.ddiSort[i].dddfId),
                        },
                    },
                })
            }
        } else if (this.props.defDossier && this.props.defDossier.sort) {
            for (let i = 0; i < this.props.defDossier.sort.length; i++) {
                sortOptions.push({
                    order: this.props.defDossier.sort[i].sort,
                    item: {
                        def_dossier_def_field: {
                            id: parseInt(this.props.defDossier.sort[i].dddfId),
                        },
                    },
                })
            }
        }

        this.state = {
            embedded: false,
            error: false,
            archived: null,
            items: [],
            actionBtns: [],
            dossierQuery: this.props.dossierQuery
                ? this.props.dossierQuery
                : null,
            inlineEdit: false,
            quickShow: true,
            quickEdit: false,
            limit: 50,
            loading: false,
            page: 0,
            sort: sortOptions,
            selected: [],
            results: [],
            subscriptions: [],
            aggregations: [],
            aggregationResults: [],
            pager: {},
            search: null,
            socketDossierIds: [],
            showActionModal: '',
        }

        this.checkAll = this.checkAll.bind(this)
        this.handleContent = this.handleContent.bind(this)
        this.isSelected = this.isSelected.bind(this)
        this.onChangePage = this.onChangePage.bind(this)
        this.getSearchFilter = this.getSearchFilter.bind(this)
        this.doSearch = this.doSearch.bind(this)
        this.fetchItems = this.fetchItems.bind(this)
        this.applySort = this.applySort.bind(this)
        this.handleEntriesChange = this.handleEntriesChange.bind(this)
        this.updateCheckedStatus = this.updateCheckedStatus.bind(this)
        this.fetchDisplayItems = this.fetchDisplayItems.bind(this)
        this.search = this.search.bind(this)
        this.onDeleteSuccess = this.onDeleteSuccess.bind(this)
        this.renderAggregateField = this.renderAggregateField.bind(this)
        this.loadSocketCallbacks = this.loadSocketCallbacks.bind(this)
        this.handleDossierModal = this.handleDossierModal.bind(this)
        this.isAllowedDd = this.isAllowedDd.bind(this)
    }

    fetchNewDossier(dossierId, isNew = true) {
        if (isNew) {
            let socketDossiers = this.state.socketDossierIds
            socketDossiers.push(dossierId)
            this.setState({ socketDossierIds: socketDossiers })
        }

        let filter = this.getSearchFilter()

        if (this.state.aggregations && this.state.aggregations.length > 0) {
            for (let i = 0; i < this.state.aggregations.length; i++) {
                filter[this.state.aggregations[i].id + '_aggregate'] =
                    this.state.aggregations[i].aggregate
            }
        }

        filter.archived = this.state.archived

        filter.dossierId = dossierId

        let originalResults = this.state.results
        this.props.kedo
            .api()
            .get(this.props.kedo.api().getContentDossierEndpoint(), {
                params: filter,
            })
            .then((response) => {
                let newContents = [
                    ...originalResults.contents,
                    ...response.data.results.contents,
                ]
                originalResults.contents = newContents

                let newDossiers = [
                    ...response.data.results.dossiers,
                    ...originalResults.dossiers,
                ]
                originalResults.dossiers = newDossiers

                this.setState({
                    error: false,
                    results: originalResults,
                })
            })
    }

    handleDossierModal(remove, close) {
        if (remove) {
            this.fetchItems()
        }

        if (close) {
            this.setState({
                inlineEdit: false,
                quickEdit: false,
                quickShow: true,
            })
        }
    }

    fetchItems() {
        let filter = this.getSearchFilter()
        this.setState({ loadingContent: true })

        if (this.state.aggregations && this.state.aggregations.length > 0) {
            for (let i = 0; i < this.state.aggregations.length; i++) {
                filter[this.state.aggregations[i].id + '_aggregate'] =
                    this.state.aggregations[i].aggregate
            }
        }

        filter.archived = this.state.archived

        this.props.kedo
            .api()
            .get(this.props.kedo.api().getContentDossierEndpoint(), {
                params: filter,
            })
            .then((response) => {
                this.setState({
                    error: false,
                    loadingContent: false,
                    results: response.data.results,
                    actionBtns: response.data.results.items.filter(
                        (item) => this.isCustomActionButton(item) === true
                    ),
                    pager: response.data.pager,
                    selected: [],
                })
            })
            .catch((error) =>
                this.setState({
                    loadingContent: false,
                    error: true,
                    expired:
                        error.response && error.response.status === 402
                            ? true
                            : false,
                })
            )
    }

    onDeleteSuccess() {
        if (this.props.kedo.socket().getSocket()) {
            this.props.kedo
                .socket()
                .getSocket()
                .emit('displayItem:delete', {
                    defDossierId: this.props.defDossierId,
                    sender: this.props.kedo.socket().getSocket().id,
                    room:
                        window.location.pathname.split('/')[1] !==
                        'contentdossier'
                            ? 'environment:' +
                              this.props.kedo.env().getEnvironmentId()
                            : null,
                })
        }

        this.fetchItems()
    }

    loadSocketCallbacks() {
        this.props.kedo
            .socket()
            .getSocket()
            .on('displayItem:fetch', (data) => {
                if (
                    this.props.kedo.socket().getSocket().id !==
                        data.sender.id &&
                    data.defDossierId === this.props.defDossierId
                ) {
                    this.fetchItems()
                }
            })
    }
    doSearch(searchParams, dossierQuery, archived) {
        this.setState(
            {
                archived: archived,
                search: searchParams,
                dossierQuery: dossierQuery,
            },
            this.fetchItems
        )
    }

    checkAll(event) {
        if (!this.state.results || !this.state.results.dossiers) {
            return
        }

        if (true === event.target.checked) {
            this.setState({
                selected: this.state.results.dossiers.map((item) => item.id),
            })
        } else {
            this.setState({ selected: [] })
        }
    }

    handleEntriesChange(event) {
        this.setState({ limit: event.target.value })
        setTimeout(() => this.fetchItems(), 200)
    }

    isCustomActionButton(listItem) {
        if (
            listItem?.def_dossier_def_field?.def_field?.type ===
            'customActionButton'
        ) {
            return true
        }

        return false
    }

    isListField(listItem) {
        if (listItem?.def_dossier_def_field?.def_field?.type === 'list') {
            return true
        }

        return false
    }

    /**
     * Check if the listItem as a specific custom field
     * @param listItem
     * @returns {boolean}
     */
    isOtherDisplayItemField(listItem) {
        if (
            !listItem.def_dossier_link &&
            !listItem.def_dossier_def_field &&
            listItem.settings &&
            listItem.settings.dossier_data
        ) {
            return true
        } else if (
            !listItem.def_dossier_link &&
            !listItem.def_dossier_def_field &&
            listItem.settings &&
            listItem.settings.block
        ) {
            return true
        } else if (this.isCustomActionButton(listItem)) {
            return true
        }

        return false
    }

    getValue(listItem, dossierData, dossier) {
        let isOtherDisplayItemField = this.isOtherDisplayItemField(listItem)
        if (!dossierData && true !== isOtherDisplayItemField) {
            return ''
        }

        let isListItem = this.isListField(listItem)
        let singleDossierData = false

        singleDossierData = dossierData.find(
            (data) => data.content.id === listItem.id
        )

        if (
            singleDossierData &&
            singleDossierData.content &&
            singleDossierData.content.skip === true
        ) {
            return ''
        }

        if (singleDossierData && singleDossierData.content) {
            singleDossierData = singleDossierData.content.content
        }

        if (
            isListItem === true &&
            listItem.settings &&
            listItem.settings.fast_switch === true
        ) {
            return (
                <DisplayItem
                    kedo={this.props.kedo}
                    changeValue={() => {
                        //TODO
                    }}
                    content={singleDossierData}
                    dossier={dossier}
                    errors={null}
                    item={listItem}
                    key={listItem.id}
                    mode={'list'}
                />
            )
        }

        if (true === isOtherDisplayItemField) {
            //Dossier data list field
        } else if (!singleDossierData || singleDossierData.length <= 0) {
            return ''
        }
        return (
            <DisplayItem
                kedo={this.props.kedo}
                changeValue={() => {
                    //TODO
                }}
                fetchItems={this.fetchItems}
                content={singleDossierData}
                dossier={dossier}
                errors={null}
                item={listItem}
                key={listItem.id}
                mode={'list'}
            />
        )
    }

    canBeArchived() {
        let dds = this.props.kedo.env().getCurrentEnvironmentDefDossiers()
        if (dds.length <= 0) {
            return false
        }

        return (
            dds.filter(
                (item) =>
                    item.id === this.props.defDossierId &&
                    item.archiveable === true
            ).length > 0
        )
    }

    updateCheckedStatus(event, dossier) {
        let selected = this.state.selected
        selected = selected.filter((checked) => checked !== dossier.id)

        if (event.target.checked === true) {
            selected.push(dossier.id)
        }

        this.setState({ selected: selected })
    }

    clearSort(e, listItem) {
        e.preventDefault()

        //Clear dossier column sorts.
        if (listItem.settings && listItem.settings.dossier_data) {
            let sortFields = this.state.sort.filter(
                (liItem) =>
                    liItem.item.setttings &&
                    liItem.item.settings.dossier_data !==
                        listItem.settings.dossier_data
            )
            this.setState({ sort: sortFields }, this.fetchItems)
            return
        }

        //Clear normal data fields.
        if (!listItem && !listItem.def_dossier_def_field) {
            return
        }

        let sortFields = this.state.sort.filter(
            (liItem) =>
                liItem.item.def_dossier_def_field.id !=
                listItem.def_dossier_def_field.id
        )
        this.setState({ sort: sortFields }, this.fetchItems)
    }

    applySort(e, listItem, listOrder) {
        e.preventDefault()

        if (
            listItem &&
            listItem.settings &&
            listItem.settings.dossier_data &&
            (listItem.settings.dossier_data === 'created_at' ||
                listItem.settings.dossier_data === 'modified_at')
        ) {
            let sortFields = this.state.sort.filter(
                (liItem) =>
                    !liItem.item ||
                    !liItem.item.settings ||
                    !liItem.item.settings.dossier_data ||
                    liItem.item.settings.dossier_data !==
                        listItem.settings.dossier_data
            )
            sortFields.push({ item: listItem, order: listOrder })
            this.setState({ sort: sortFields }, this.fetchItems)

            return
        }

        if (!listItem && !listItem.def_dossier_def_field) {
            return
        }

        let sortFields = this.state.sort.filter(
            (liItem) =>
                liItem.item.def_dossier_def_field.id !=
                listItem.def_dossier_def_field.id
        )
        sortFields.push({ item: listItem, order: listOrder })
        this.setState({ sort: sortFields }, this.fetchItems)
    }

    isSortable(listField) {
        if (
            listField &&
            listField.settings &&
            listField.settings.dossier_data &&
            (listField.settings.dossier_data === 'created_at' ||
                listField.settings.dossier_data === 'modified_at')
        ) {
            return true
        }

        if (!listField || !listField.def_dossier_def_field) {
            return false
        }

        if (
            listField.def_dossier_def_field &&
            listField.def_dossier_def_field.def_field.type === 'textarea'
        ) {
            return false
        }

        return true
    }

    isAllowedDd(credential) {
        const kedo = this.props.kedo

        return kedo
            .env()
            .isAllowedDefDossier(
                credential,
                this.props.defDossierId,
                kedo.user()
            )
    }

    renderTableHead(results, actionBtns) {
        if (!results || !results.items) {
            return
        }

        let ddArchiveable = this.canBeArchived()
        let listItems = results.items.filter(
            (item) => item.view === 'list' && !this.isCustomActionButton(item)
        )
        let allDossiers = results.dossiers
        let selectedItems = this.state.selected
        let selectedDossiers = allDossiers.filter((item) =>
            selectedItems.find((selectedId) => selectedId === item.id)
        )
        let archivedDossiers =
            ddArchiveable &&
            selectedDossiers.find((item) => item.archived === true)
        let dearchivedDossiers =
            ddArchiveable &&
            selectedDossiers.find((item) => item.archived === false)
        const kedo = this.props.kedo

        let extraColumns = 3
        if (actionBtns.length > 0) {
            extraColumns = 4
        }

        return (
            <thead>
                <tr>
                    <td colSpan={listItems.length + extraColumns}>
                        {selectedItems.length > 0 ? (
                            <ButtonGroup size={'sm'}>
                                {this.isAllowedDd('delete') ? (
                                    <Button
                                        size={'sm'}
                                        className={
                                            'd-flex justify-content-around align-items-center'
                                        }
                                        onClick={(event) => {
                                            event.preventDefault()
                                            if (selectedDossiers.length <= 0) {
                                                alert(
                                                    kedo.t(
                                                        'Select dossiers first'
                                                    )
                                                )
                                                return
                                            }
                                            this.setState({
                                                showActionModal: 'delete',
                                            })
                                        }}
                                        title={kedo.t('Delete')}
                                        variant={'outline-danger'}
                                    >
                                        <FontAwesomeIcon
                                            className={'mr-2'}
                                            icon={faTrash}
                                        />
                                        <span>{kedo.t('Delete')}</span>
                                    </Button>
                                ) : null}
                                <Button
                                    size={'sm'}
                                    className={
                                        'd-flex justify-content-around align-items-center'
                                    }
                                    onClick={(event) => {
                                        event.preventDefault()
                                        if (selectedDossiers.length <= 0) {
                                            alert(
                                                kedo.t('Select dossiers first')
                                            )
                                            return
                                        }
                                        this.setState({
                                            showActionModal: 'export',
                                        })
                                    }}
                                    title={kedo.t('Export')}
                                    variant={'outline-secondary'}
                                >
                                    <FontAwesomeIcon
                                        className={'mr-2'}
                                        icon={faFileExport}
                                    />
                                    {kedo.t('Export')}
                                </Button>
                                <Dropdown
                                    onSelect={(eventKey, event) => {
                                        event.preventDefault()
                                        if (selectedDossiers.length <= 0) {
                                            alert(
                                                kedo.t('Select dossiers first')
                                            )
                                            return
                                        }

                                        switch (eventKey) {
                                            default:
                                                this.setState({
                                                    showActionModal: eventKey,
                                                })
                                                break
                                        }
                                    }}
                                    as={ButtonGroup}
                                >
                                    <Dropdown.Toggle
                                        title={kedo.t('More actions')}
                                        variant={'outline-secondary'}
                                        id="dropdown-custom-components"
                                    >
                                        <FontAwesomeIcon
                                            className={'mr-2'}
                                            icon={faBars}
                                        />{' '}
                                        {kedo.t('More actions')}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="subscribe">
                                            <FontAwesomeIcon
                                                className={'mr-2'}
                                                icon={faBell}
                                            />
                                            {kedo.t('Subscription')}
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="stopsubscription">
                                            <FontAwesomeIcon
                                                className={'mr-2'}
                                                icon={faBellSlash}
                                            />
                                            {kedo.t('Stop subscription')}
                                        </Dropdown.Item>
                                        {dearchivedDossiers &&
                                        this.isAllowedDd('archive') ? (
                                            <Dropdown.Item eventKey="archive">
                                                <FontAwesomeIcon
                                                    className={'mr-2'}
                                                    icon={faFolder}
                                                />
                                                {kedo.t('Archive')}
                                            </Dropdown.Item>
                                        ) : null}
                                        {archivedDossiers &&
                                        this.isAllowedDd('archive') ? (
                                            <Dropdown.Item eventKey="dearchive">
                                                <FontAwesomeIcon
                                                    className={'mr-2'}
                                                    icon={faFolder}
                                                />
                                                {kedo.t('Dearchive')}
                                            </Dropdown.Item>
                                        ) : null}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </ButtonGroup>
                        ) : null}
                        {this.state.showActionModal === 'delete' ? (
                            <MultiContentDossierDelete
                                kedo={kedo}
                                showModal={true}
                                size={'sm'}
                                onCancel={() => {
                                    this.setState({
                                        showActionModal: '',
                                    })
                                }}
                                onClose={() => {
                                    this.setState({
                                        showActionModal: '',
                                    })
                                    this.onDeleteSuccess()
                                }}
                                items={selectedItems}
                                mode={this.props.mode}
                            />
                        ) : null}
                        {this.state.showActionModal === 'export' &&
                        kedo
                            .env()
                            .isAllowedDefDossier(
                                'export',
                                this.props.defDossierId,
                                kedo.user()
                            ) ? (
                            <ContentDossierExport
                                showModal={
                                    this.state.showActionModal === 'export'
                                }
                                defDossierId={this.props.defDossierId}
                                filter={this.getSearchFilter}
                                size={'sm'}
                                kedo={kedo}
                                onCancel={() => {
                                    this.setState({ showActionModal: '' })
                                }}
                                onClose={() => {
                                    this.fetchItems()
                                    this.setState({ showActionModal: '' })
                                }}
                                selected={selectedItems}
                                mode={this.props.mode}
                            />
                        ) : null}
                        {this.state.showActionModal === 'subscribe' ||
                        this.state.showActionModal === 'stopsubscription' ? (
                            <MultiContentDossierSubscribe
                                kedo={kedo}
                                showModal={true}
                                onClose={() => {
                                    this.fetchItems()
                                    this.setState({ showActionModal: '' })
                                }}
                                showActionModal={this.state.showActionModal}
                                subscriptions={this.state.subscriptions}
                                items={selectedItems}
                                mode={this.props.mode}
                            />
                        ) : null}
                        {this.state.showActionModal === 'archive' ||
                        (this.state.showActionModal === 'dearchive' &&
                            kedo
                                .env()
                                .isAllowedDefDossier(
                                    'archive',
                                    this.props.defDossierId,
                                    kedo.user()
                                )) ? (
                            <MultiContentDossierArchive
                                kedo={kedo}
                                showModal={true}
                                onClose={() => {
                                    this.fetchItems()
                                    this.setState({ showActionModal: '' })
                                }}
                                archived={
                                    this.state.showActionModal === 'archive'
                                }
                                checked={this.state.selected}
                                mode={this.props.mode}
                            />
                        ) : null}
                        <div className={'float-right'}>
                            {this.props.multiAdd ? this.props.multiAdd : null}
                            {this.props.mode === 'embeddedList' &&
                            this.props.addDossier
                                ? this.props.addDossier
                                : null}{' '}
                            &nbsp;
                            {this.props.mode === 'embeddedList' &&
                            this.props.filterDossier
                                ? this.props.filterDossier
                                : null}{' '}
                            &nbsp;
                            {allDossiers.length > 0 &&
                            selectedItems.length <= 0 &&
                            kedo
                                .env()
                                .isAllowedDefDossier(
                                    'export',
                                    this.props.defDossierId,
                                    this.props.kedo.user()
                                ) ? (
                                <ContentDossierExport
                                    defDossierId={this.props.defDossierId}
                                    kedo={kedo}
                                    onClose={() =>
                                        this.setState({
                                            showAllExportModal: false,
                                        })
                                    }
                                    filter={this.getSearchFilter}
                                    mode={'all'}
                                />
                            ) : null}
                            {allDossiers.length > 0 &&
                            selectedItems.length <= 0 ? (
                                <ShowEntries
                                    pager={this.state.pager}
                                    value={this.state.limit}
                                    onChange={this.handleEntriesChange}
                                />
                            ) : null}{' '}
                            &nbsp;
                        </div>
                    </td>
                </tr>
                {!this.state.results ||
                !this.state.results.dossiers ||
                this.state.results.dossiers.length <= 0 ? (
                    <Alert variant={'info'} style={{ margin: '0 17px' }}>
                        <FontAwesomeIcon icon={faExclamationCircle} />
                        &nbsp; {this.props.kedo.t('No items found')}
                    </Alert>
                ) : (
                    <tr>
                        <th style={{ width: '3%' }}>
                            {allDossiers.length > 0 ? (
                                <input
                                    checked={
                                        this.state.selected.length ===
                                        this.state.results.dossiers.length
                                    }
                                    onChange={this.checkAll}
                                    type="checkbox"
                                    style={{ marginRight: '0px' }}
                                />
                            ) : null}
                        </th>
                        {this.props.showEmail === true ? <th /> : null}
                        <th style={{ textAlign: 'center' }}>
                            <FontAwesomeIcon icon={faBell} />
                        </th>
                        {listItems.map((listItem) => {
                            let isSortable = this.isSortable(listItem)
                            let sortItem = null
                            if (
                                listItem.def_dossier_def_field &&
                                listItem.def_dossier_def_field.id
                            ) {
                                sortItem = this.state.sort.find(
                                    (itList) =>
                                        itList.item.def_dossier_def_field &&
                                        itList.item.def_dossier_def_field.id ===
                                            listItem.def_dossier_def_field.id
                                )
                            } else if (
                                listItem.settings &&
                                listItem.settings.dossier_data
                            ) {
                                sortItem = this.state.sort.find(
                                    (itList) =>
                                        itList.item.settings &&
                                        listItem.settings.dossier_data ===
                                            itList.item.settings.dossier_data
                                )
                            }

                            //Item is not sortable
                            if (!isSortable) {
                                return (
                                    <th
                                        data-ddiid={listItem.id}
                                        key={listItem.id}
                                    >
                                        {kedo
                                            .env()
                                            .translateItem(
                                                listItem,
                                                'displayitem'
                                            )}
                                        <AdminQuickEditMenu
                                            className={
                                                'list-admin-edit-buttons'
                                            }
                                            kedo={kedo}
                                            item={listItem}
                                        />
                                    </th>
                                )
                            }

                            //Item is sortable
                            return (
                                <th data-ddiid={listItem.id} key={listItem.id}>
                                    <span
                                        onClick={(e) =>
                                            this.applySort(
                                                e,
                                                listItem,
                                                sortItem &&
                                                    sortItem.order === 'ASC'
                                                    ? 'DESC'
                                                    : 'ASC'
                                            )
                                        }
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {kedo
                                            .env()
                                            .translateItem(
                                                listItem,
                                                'displayitem'
                                            )}{' '}
                                        &nbsp;
                                        {sortItem ? (
                                            <FontAwesomeIcon
                                                icon={
                                                    sortItem.order === 'ASC'
                                                        ? faSortUp
                                                        : faSortDown
                                                }
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                className={'text-muted'}
                                                icon={faSort}
                                            />
                                        )}
                                        &nbsp;&nbsp;
                                    </span>
                                    {sortItem ? (
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            onClick={(e) =>
                                                this.clearSort(e, listItem)
                                            }
                                            size={'sm'}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    ) : null}
                                    <AdminQuickEditMenu
                                        className={'list-admin-edit-buttons'}
                                        kedo={kedo}
                                        item={listItem}
                                    />
                                </th>
                            )
                        })}
                        {actionBtns.length > 0 ? (
                            <th>{kedo.t('Actions')}</th>
                        ) : null}
                        <th />
                    </tr>
                )}
            </thead>
        )
    }

    onChangePage(number) {
        this.setState({ page: number })
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.page = number
        this.fetchItems()
    }

    fetchDisplayItems() {
        if (this.state.loading) {
            return
        }
        const api = this.props.kedo.api()

        let params = {
            params: {
                view: ['search', 'advanced-search'],
                defDossier: this.props.defDossierId,
                limit: 200,
            },
        }

        api.getCached(api.getDisplayItemEndpoint(), params).then((response) => {
            this.setState({
                loading: false,
                items: response.data.results,
            })
            this.fetchItems()
        })
    }

    renderTableContent(results) {
        if (
            !this.state.results ||
            !this.state.results.dossiers ||
            this.state.results.dossiers.length <= 0
        ) {
            return
        }

        let listItems = results.items.filter(
            (item) => item.view === 'list' && !this.isCustomActionButton(item)
        )

        return (
            <tbody>
                {results.dossiers.map((dossier) =>
                    this.renderContentRow(
                        results.contents.filter(
                            (dataItem) => dataItem.id === dossier.id
                        ),
                        dossier,
                        listItems,
                        this.state.actionBtns
                    )
                )}
            </tbody>
        )
    }

    isSelected(dossier) {
        return this.state.selected.find(
            (selectedId) => selectedId === dossier.id
        )
    }

    renderContentRow(dossierData, dossier, listItems, actionBtns) {
        let selected = this.isSelected(dossier)

        let doTransition =
            this.props.mode === 'embeddedList' &&
            this.state.socketDossierIds.find((item) => item === dossier.id)
                ? true
                : false
        let style = {
            borderLeft: doTransition ? '8px solid #bddbf0' : '',
            fontWeight: doTransition ? 'bold' : '',
        }

        return (
            <>
                <tr
                    key={dossier.id}
                    style={style}
                    className={`contentRow ${
                        dossier.archived ? 'archivedDossier' : ''
                    }`}
                >
                    <td className={'contentData'} style={{ width: '3%' }}>
                        <Form.Check
                            checked={selected}
                            inline
                            label={''}
                            onClick={(event) =>
                                this.updateCheckedStatus(event, dossier)
                            }
                            style={{ marginRight: '0px' }}
                            type={'checkbox'}
                            value={dossier.id}
                        />
                    </td>
                    {this.props.showEmail === true ? (
                        <td>
                            <ContentDossierSend
                                kedo={this.props.kedo}
                                dossier={dossier}
                            />
                        </td>
                    ) : null}
                    <td
                        className={'contentData'}
                        style={{ textAlign: 'center', width: '3%' }}
                    >
                        {this.state.subscriptions.find(
                            (item) => item.content_dossier_id === dossier.id
                        ) ? (
                            <FontAwesomeIcon icon={faBell} />
                        ) : null}
                    </td>
                    {listItems.map((listItem) => (
                        <td
                            style={{ cursor: 'pointer' }}
                            onClick={(event) => {
                                if (
                                    this.isCustomActionButton(listItem) ===
                                    false
                                ) {
                                    this.handleContent(event, dossier)
                                }
                            }}
                            className={'contentData'}
                            key={listItem.id}
                        >
                            {this.getValue(listItem, dossierData, dossier)}
                        </td>
                    ))}
                    {actionBtns.length > 0 ? (
                        <td className={'contentData'}>
                            <ButtonGroup
                                className={'displayItem'}
                                vertical
                                aria-label="Actions"
                            >
                                {actionBtns.map((actionBtn) =>
                                    this.getValue(
                                        actionBtn,
                                        dossierData,
                                        dossier
                                    )
                                )}
                            </ButtonGroup>
                        </td>
                    ) : null}
                    <td className={'tc-inline-edit'}>
                        <Button
                            size={'sm'}
                            className={'float-right dossier-inline-edit'}
                            onClick={() => {
                                let socketDossiers =
                                    this.state.socketDossierIds.filter(
                                        (item) => item !== dossier.id
                                    )
                                this.setState({
                                    inlineEdit: dossier.id,
                                    socketDossierIds: socketDossiers,
                                    quickEdit: true,
                                    quickShow: false,
                                })
                            }}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    </td>
                </tr>
            </>
        )
    }

    handleContent(event, dossier) {
        event.stopPropagation()

        let trigger = event.target
        if (trigger.nodeName === 'INPUT') {
            return
        }
        if (
            trigger.className &&
            trigger.className.includes &&
            (trigger.className.includes('contentData') ||
                trigger.className.includes('displayItem') ||
                trigger.className.includes('list-group-item'))
        ) {
            if (this.props.embedded) {
                this.setState({ inlineEdit: dossier.id })
            } else {
                if (
                    event.ctrlKey ||
                    (navigator.platform == 'MacIntel' && event.metaKey)
                ) {
                    window.open(`/contentdossier/${dossier.id}`, '_blank')
                    return
                }
                this.props.onClick(dossier)
                this.toTop()
            }
        }
    }

    renderAggregateField(field) {
        let aggrOptions = []

        if (field.def_dossier_link) {
            aggrOptions = ['unique']
        } else if (field.def_dossier_def_field) {
            if (field.def_dossier_def_field.def_field.type === 'amount') {
                aggrOptions = ['avg', 'sum', 'min', 'max']
            } else if (field.def_dossier_def_field.def_field.type === 'time') {
                aggrOptions = ['avg', 'sum', 'min', 'max']
            } else if (field.def_dossier_def_field.def_field.type === 'date') {
                aggrOptions = ['min', 'max']
            } else if (field.def_dossier_def_field.def_field.type === 'list') {
                aggrOptions = ['unique']
            }
        }
        if (aggrOptions.length <= 0) {
            return null
        }

        let curVal = this.state.aggregations.find(
            (item) => item.id === field.id
        )
        return (
            <Dropdown className={'float-right aggregate'} size="sm">
                <Dropdown.Toggle size="sm" key={`dd-aggr-${field.id}`} />

                <Dropdown.Menu size="sm" align={'right'}>
                    <Dropdown.Item
                        onClick={() => {
                            let aggrs = this.state.aggregations.filter(
                                (aggrItem) => aggrItem.id !== field.id
                            )
                            this.setState(
                                { aggregations: aggrs },
                                this.fetchItems
                            )
                        }}
                    >
                        &nbsp;
                    </Dropdown.Item>
                    {aggrOptions.map((aggrOption, aggrIndex) => (
                        <Dropdown.Item
                            key={aggrIndex}
                            active={curVal && curVal.aggregate === aggrOption}
                            onClick={() => {
                                let aggrs = this.state.aggregations.filter(
                                    (aggrItem) => aggrItem.id !== field.id
                                )
                                aggrs.push({
                                    id: field.id,
                                    aggregate: aggrOption,
                                })

                                this.setState(
                                    { aggregations: aggrs },
                                    this.fetchItems
                                )
                            }}
                        >
                            {this.props.kedo.t(aggrOption.toUpperCase())}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    renderTableFooter(results, pager, actionBtns) {
        if (!results || !results.dossiers || results.dossiers.length <= 0) {
            return
        }

        let listItems = results.items.filter(
            (item) => item.view === 'list' && !this.isCustomActionButton(item)
        )

        let extraColumns = 3
        if (actionBtns.length > 0) {
            extraColumns = 4
        }

        return (
            <tfoot>
                <tr className={'tr-aggr'}>
                    <td style={{ width: '3%' }} />
                    <td style={{ textAlign: 'center' }} />
                    {listItems.map((listItem, lIndex) => (
                        <td key={lIndex}>
                            {this.renderAggregateField(listItem)}
                            {listItem && listItem.def_dossier_link ? (
                                <div className={'aggr-result'}>
                                    {results &&
                                    results.aggregations &&
                                    results.aggregations[listItem.id]
                                        ? results.aggregations[listItem.id].map(
                                              (aggrItem, aggrIndex) => (
                                                  <Badge
                                                      key={aggrIndex}
                                                      className={'mr-1'}
                                                  >
                                                      <Link
                                                          to={`/contentdossier/${aggrItem.id}`}
                                                      >
                                                          {aggrItem.summary}
                                                      </Link>{' '}
                                                      <span
                                                          className={
                                                              'badge badge-light'
                                                          }
                                                      >
                                                          {aggrItem.total}
                                                      </span>
                                                  </Badge>
                                              )
                                          )
                                        : null}
                                </div>
                            ) : null}
                            {listItem.def_dossier_def_field ? (
                                <div className={'aggr-result'}>
                                    {results &&
                                    results.aggregations &&
                                    results.aggregations[listItem.id] &&
                                    listItem.def_dossier_def_field.def_field
                                        .type === 'date'
                                        ? this.props.kedo
                                              .utils()
                                              .dateFormat(
                                                  results.aggregations[
                                                      listItem.id
                                                  ]
                                              )
                                        : null}
                                    {results &&
                                    results.aggregations &&
                                    results.aggregations[listItem.id] &&
                                    (listItem.def_dossier_def_field.def_field
                                        .type === 'time' ||
                                        listItem.def_dossier_def_field.def_field
                                            .type === 'amount')
                                        ? results.aggregations[listItem.id]
                                        : null}
                                    {results &&
                                    results.aggregations &&
                                    results.aggregations[listItem.id] &&
                                    listItem.def_dossier_def_field.def_field
                                        .type === 'list'
                                        ? listItem.def_dossier_def_field.def_field.def_field_lists
                                              .filter((defFieldListItem) =>
                                                  results.aggregations[
                                                      listItem.id
                                                  ].find(
                                                      (selItem) =>
                                                          selItem.id ===
                                                          defFieldListItem.id
                                                  )
                                              )
                                              .map(
                                                  (
                                                      defFieldListItem,
                                                      dIndex
                                                  ) => (
                                                      <Badge
                                                          key={dIndex}
                                                          className={'mr-1'}
                                                          style={{
                                                              backgroundColor:
                                                                  defFieldListItem.color
                                                                      ? defFieldListItem.color
                                                                      : null,
                                                          }}
                                                      >
                                                          {this.props.kedo.translateItem(
                                                              defFieldListItem,
                                                              'def_field_list'
                                                          )}{' '}
                                                          <span
                                                              className={
                                                                  'badge badge-light'
                                                              }
                                                          >
                                                              {
                                                                  results.aggregations[
                                                                      listItem
                                                                          .id
                                                                  ].find(
                                                                      (
                                                                          selItem
                                                                      ) =>
                                                                          selItem.id ===
                                                                          defFieldListItem.id
                                                                  ).total
                                                              }
                                                          </span>
                                                      </Badge>
                                                  )
                                              )
                                        : null}
                                </div>
                            ) : null}
                        </td>
                    ))}
                    <td colSpan={extraColumns - 2} />
                </tr>
                <tr>
                    <td colSpan={results.items.length + extraColumns}>
                        <Pagination
                            pager={pager}
                            onChangePage={this.onChangePage}
                        />
                        <ItemCounter pager={pager} />
                    </td>
                </tr>
            </tfoot>
        )
    }

    toTop() {
        window.scrollTo(0, 0)
    }

    fetchSubscriptions() {
        if (
            !this.state.results ||
            !this.state.results.dossiers ||
            this.state.results.dossiers.length <= 0
        ) {
            return
        }
        let ids = this.state.results.dossiers.map((item) => item.id)
        this.props.kedo
            .api()
            .post(
                this.props.kedo.api().getContentDossierEndpoint() +
                    '/subscriptions',
                { ids: ids }
            )
            .then((response) => this.setState({ subscriptions: response.data }))
    }

    getSearchFilter() {
        let filter = {
            defDossier: this.props.defDossierId,
            limit: this.state.limit,
            page: this.state.page,
        }

        if (this.state.dossierQuery && this.state.dossierQuery.length > 0) {
            filter.dossierQuery = this.state.dossierQuery
        }

        if (this.state.sort && this.state.sort.length > 0) {
            filter.sort = []
            filter.sortOrder = []
            this.state.sort.map((filterItem) => {
                filter.sort.push(
                    filterItem.item.def_dossier_def_field
                        ? filterItem.item.def_dossier_def_field.id
                        : filterItem.item.settings.dossier_data
                )
                filter.sortOrder.push(filterItem.order)
            })
        }

        if (this.props.embedded) {
            filter.embedded = this.props.embedded
            filter.linkId = this.props.linkId
        }

        if (this.state.search && this.state.search.length > 0) {
            this.state.search.map((searchItem) => {
                if (
                    searchItem &&
                    searchItem.value &&
                    searchItem.value.custom === 'date'
                ) {
                    filter[searchItem.id + '_type'] = searchItem.value.type
                    if (searchItem.value.type === 'between') {
                        filter[searchItem.id + '_end'] = searchItem.value.date2
                    }
                    filter[searchItem.id] = searchItem.value.date1
                } else if (typeof searchItem.value === 'string') {
                    filter[searchItem.id] = searchItem.value
                } else if (
                    searchItem &&
                    searchItem.value &&
                    searchItem.value.custom === 'slider'
                ) {
                    filter[searchItem.id + '_min'] = searchItem.value.min
                    filter[searchItem.id + '_max'] = searchItem.value.max
                    filter[searchItem.id] = '1'
                } else if (searchItem.value instanceof Array) {
                    let values = []
                    searchItem.value.map((valItem) => values.push(valItem.id))
                    filter[searchItem.id] = values
                }
            })
        }

        return filter
    }

    search(values, dossierQuery) {
        this.setState(
            {
                search: values,
                dossierQuery: dossierQuery,
            },
            this.fetchItems
        )
    }

    renderSearchFilter() {
        let searchItems = this.state.items.filter(
            (searchItem) =>
                searchItem.view === 'search' ||
                searchItem.view === 'advanced-search'
        )

        if (
            searchItems.length <= 0 ||
            this.props.embedded ||
            this.props.initSearch === false
        ) {
            return null
        }
        let defDossier = this.props.kedo
            .env()
            .getCurrentEnvironmentDefDossiers()
            .find((item) => item.id === parseInt(this.props.defDossierId))

        return (
            <SearchDefDossier
                doSearch={this.doSearch}
                kedo={this.props.kedo}
                defDossierId={this.props.defDossierId}
                dossierQuery={this.state.dossierQuery}
                defDossier={defDossier}
                items={searchItems}
                showArchive={this.canBeArchived()}
                resetSearch={() => this.setState({ search: null })}
                search={this.state.search}
                location={'DefDossierIndex'}
            />
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.dossierQuery !== prevProps.dossierQuery) {
            this.fetchDisplayItems()
        }
    }

    componentDidMount() {
        if (this.props.kedo.socket().getSocket()) {
            this.loadSocketCallbacks()
        }

        this.fetchDisplayItems()
    }

    componentWillUnmount() {
        if (this.socket) {
            this.socket.removeListener('displayItem:fetch')
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <div className={'container-fluid'}>
                    <LoadingData />
                </div>
            )
        }
        if (this.state.error) {
            return (
                <div className={'col-md-12'}>
                    {this.state.expired ? (
                        <ExpiredModal kedo={this.props.kedo} />
                    ) : null}
                    <Alert variant={'warning'}>
                        <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                        {this.props.kedo.t('Something went wrong')}.
                    </Alert>
                </div>
            )
        }

        let listItems =
            this.state.results.items && this.state.results.items.filter
                ? this.state.results.items.filter(
                      (item) => item.view === 'list'
                  )
                : 0

        let extraColumns = this.state.actionBtns.length > 0 ? 4 : 3

        return (
            <div>
                {this.renderSearchFilter()}
                <Table size={'sm'} striped hover responsive>
                    {this.renderTableHead(
                        this.state.results,
                        this.state.actionBtns
                    )}
                    {this.state.loadingContent ? (
                        <tbody>
                            <tr>
                                <td
                                    className={'text-center'}
                                    colSpan={listItems.length + extraColumns}
                                >
                                    <LoadingDefault />
                                </td>
                            </tr>
                        </tbody>
                    ) : (
                        this.renderTableContent(this.state.results)
                    )}
                    {this.state.loadingContent ? (
                        <tfoot>
                            <tr>
                                <td
                                    className={'text-center'}
                                    colSpan={listItems.length + extraColumns}
                                >
                                    <LoadingDefault />
                                </td>
                            </tr>
                        </tfoot>
                    ) : (
                        this.renderTableFooter(
                            this.state.results,
                            this.state.pager,
                            this.state.actionBtns
                        )
                    )}
                </Table>
                {this.state.inlineEdit ? (
                    <DossierModalEditButton
                        onDeleteSuccess={() => this.handleDossierModal(true)}
                        showSaveClose={true}
                        onSuccess={(close) => {
                            this.handleDossierModal(true, close)
                        }}
                        onClose={() => this.handleDossierModal(false, true)}
                        embedded={this.props.embedded}
                        edit={true}
                        dossierId={this.state.inlineEdit}
                        kedo={this.props.kedo}
                        quickEdit={this.state.quickEdit}
                        quickShow={this.state.quickShow}
                    />
                ) : null}
            </div>
        )
    }
}

export default DefDossierList
