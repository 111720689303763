import React, { Component } from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap'
import LoadingDefault from '../../../Elements/Loading/LoadingDefault'
import { faSave } from '@fortawesome/pro-duotone-svg-icons/faSave'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ConfirmDeleteChangesDialog from '../../../Form/ConfirmDeleteChangesDialog'

class NewFile extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fileContent: props.dataSignature ? props.dataSignature : null,
            name: null,
            description: null,
            submitting: false,
            errors: false,
            multiple: false,
            files: [],
            fileData: [],
        }

        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleErrors = this.handleErrors.bind(this)
        this.handleSuccess = this.handleSuccess.bind(this)
        this.handleFileChange = this.handleFileChange.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.submitFile = this.submitFile.bind(this)
        this.handleOptionMultiple = this.handleOptionMultiple.bind(this)
    }

    /**
     * @param response
     * @returns {boolean}
     */
    hasFormErrors(response) {
        return (
            response.response.status === 400 &&
            response.response.data &&
            response.response.data.errors
        )
    }

    handleSuccess(response, item) {
        let newFile = {}
        if (!item) {
            newFile = {
                id: response.data.id,
                filename: this.state.name,
                description: this.state.description,
            }
        } else {
            newFile = {
                id: response.data.id,
                filename: item.name,
                description: item.description,
            }
        }

        this.setState({
            submitting: false,
            errors: false,
        })

        this.props.onSuccess(newFile)
    }

    handleErrors(response) {
        if (!this.hasFormErrors(response)) {
            this.setState({
                submitting: false,
                errors: {
                    generic: 'Something went wrong.',
                },
            })
        } else {
            this.setState({
                submitting: false,
                errors: response.response.data.errors,
            })
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    submitFile() {
        let errors = {}
        if (!this.state.multiple) {
            if (!this.state.name || !this.state.fileContent) {
                if (!this.state.name || this.state.name.length <= 0) {
                    errors.name = this.props.kedo.t('This is a required field')
                }
                if (
                    !this.state.fileContent ||
                    this.state.fileContent.length <= 0
                ) {
                    errors.fileContent = this.props.kedo.t(
                        'This is a required field'
                    )
                }

                this.setState({
                    errors: errors,
                    saving: false,
                })

                return
            }

            this.setState({
                submitting: true,
                errors: false,
            })

            let item = {
                name: this.state.name,
                description: this.state.description,
                file: this.state.fileContent,
                environment: this.props.kedo.env().getEnvironment().id,
            }

            this.props.kedo
                .api()
                .post(this.props.kedo.api().getFileEndpoint(), item)
                .then((response) => this.handleSuccess(response))
                .catch((errResponse) => this.handleErrors(errResponse))
        } else {
            let errors = { ...this.state.errors }

            if (!this.state.fileData || this.state.fileData.length <= 0) {
                errors.fileContent = this.props.kedo.t(
                    'This is a required field'
                )
                this.setState({
                    errors: errors,
                    saving: false,
                })
                return
            }

            this.setState({
                submitting: true,
                errors: false,
            })

            let hasErrors = false

            this.state.fileData.forEach((file, index) => {
                if (!file.name || file.name.length === 0) {
                    errors[index] = {
                        name: this.props.kedo.t('This is a required field'),
                    }
                    hasErrors = true
                }
            })

            if (hasErrors) {
                this.setState({
                    errors: errors,
                    saving: false,
                    submitting: false,
                })
                return
            }

            this.state.fileData.forEach((file) => {
                let item = {
                    name: file.name,
                    description: file.description,
                    file: file.fileContent,
                    environment: this.props.kedo.env().getEnvironment().id,
                }

                this.props.kedo
                    .api()
                    .post(this.props.kedo.api().getFileEndpoint(), item)
                    .then((response) => this.handleSuccess(response, item))
                    .catch((errResponse) => this.handleErrors(errResponse))
            })
        }
    }

    renderErrorFeedback(errorMsg) {
        if (!errorMsg) {
            return
        }

        return (
            <Form.Control.Feedback type="invalid">
                {errorMsg}
            </Form.Control.Feedback>
        )
    }

    handleFileChange(e) {
        e.preventDefault()

        let reader = new FileReader()
        let file = e.target.files[0]

        reader.onloadend = () => {
            this.setState({
                fileContent: reader.result,
                name:
                    !this.state.name || this.state.name.length <= 0
                        ? file.name
                        : this.state.name,
            })
        }

        reader.readAsDataURL(file)
    }

    getAccepts(accepts) {
        let acceptTypes = ''
        accepts.map((item) => {
            if (item === 'images') {
                acceptTypes =
                    acceptTypes +
                    (acceptTypes.length > 0 ? ',' : '') +
                    'image/x-png,image/gif,image/jpeg'
            } else if (item === 'documents') {
                acceptTypes =
                    acceptTypes +
                    (acceptTypes.length > 0 ? ',' : '') +
                    'application/msword,text/plain, application/pdf'
            } else if (item === 'spreadsheets') {
                acceptTypes =
                    acceptTypes +
                    (acceptTypes.length > 0 ? ',' : '') +
                    'application/vnd.ms-excel'
            }
        })

        return acceptTypes
    }

    handleCloseModal(changes) {
        if (changes) {
            this.setState({ showConfirmChanges: true })
            return this.renderRemoveChangesForm()
        }

        return this.props.onClose()
    }

    renderRemoveChangesForm() {
        if (this.state.showConfirmChanges) {
            return (
                <ConfirmDeleteChangesDialog
                    kedo={this.props.kedo}
                    variant={'modal'}
                    accept={this.handleClose}
                    cancel={() => this.setState({ showConfirmChanges: false })}
                />
            )
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.name !== this.state.name ||
            prevState.description !== this.state.description ||
            prevState.selectedCredentials !== this.state.selectedCredentials
        ) {
            this.setState({ unsavedChanges: true })
        }
    }

    handleOptionMultiple() {
        this.setState((prevState) => ({
            multiple: !prevState.multiple,
        }))
    }

    handleMultipleFileChange = (event) => {
        const files = Array.from(event.target.files)
        const fileDataPromises = files.map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onloadend = () => {
                    resolve({
                        file: file,
                        name: file.name,
                        description: file.description || '',
                        fileContent: reader.result,
                    })
                }
                reader.onerror = reject
                reader.readAsDataURL(file)
            })
        })

        Promise.all(fileDataPromises)
            .then((fileData) => {
                this.setState({
                    files: files,
                    fileData: fileData,
                })
            })
            .catch((error) => {
                console.error('Error reading files:', error)
            })
    }

    handleFileNameChange = (e, index) => {
        const newFileData = [...this.state.fileData]
        newFileData[index].name = e.target.value

        let errors = { ...this.state.errors }
        if (e.target.value) {
            delete errors[index]
        }

        this.setState({
            fileData: newFileData,
            errors: errors,
        })
    }

    render() {
        return (
            <div>
                {this.state.showConfirmChanges
                    ? this.renderRemoveChangesForm()
                    : null}
                <Modal
                    show={true}
                    onHide={() =>
                        this.handleCloseModal(this.state.unsavedChanges)
                    }
                    size={this.state.multiple ? 'lg' : 'md'}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.kedo.t('New file')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Check
                            className="mb-2"
                            type="checkbox"
                            id="keep"
                            label={
                                <strong>
                                    {this.props.kedo.t('Multiple files')}
                                </strong>
                            }
                            checked={this.state.multiple}
                            onChange={this.handleOptionMultiple}
                        />
                        {!this.state.multiple ? (
                            <Form>
                                {!this.props.dataSignature ? (
                                    <Form.Group controlId="file.image">
                                        <Form.Label className={'required'}>
                                            {this.props.kedo.t('File')}
                                        </Form.Label>
                                        <section
                                            className={
                                                'fakeInputField ' +
                                                (this.state.errors &&
                                                !this.state.fileContent
                                                    ? 'fake-feedback'
                                                    : '')
                                            }
                                        >
                                            {this.props.accepts ? (
                                                <input
                                                    name={'file'}
                                                    accept={this.getAccepts(
                                                        this.props.accepts
                                                    )}
                                                    onChange={
                                                        this.handleFileChange
                                                    }
                                                    type="file"
                                                />
                                            ) : (
                                                <input
                                                    name={'file'}
                                                    onChange={
                                                        this.handleFileChange
                                                    }
                                                    type="file"
                                                />
                                            )}
                                        </section>
                                    </Form.Group>
                                ) : null}
                                {this.state.errors &&
                                this.state.errors.fileContent
                                    ? this.renderErrorFeedback(
                                          this.state.errors.fileContent
                                      )
                                    : null}
                                <Form.Label className={'required'}>
                                    {this.props.kedo.t('Name')}
                                </Form.Label>
                                <Form.Control
                                    name={'name'}
                                    value={this.state.name}
                                    readOnly={this.state.submitting}
                                    type={'text'}
                                    onChange={(e) => this.handleChange(e)}
                                    isInvalid={
                                        this.state.errors != null &&
                                        this.state.errors.name
                                    }
                                />
                                {this.state.errors && this.state.errors.name
                                    ? this.renderErrorFeedback(
                                          this.state.errors.name
                                      )
                                    : null}
                                <Form.Label>
                                    {this.props.kedo.t('Description')}
                                </Form.Label>
                                <Form.Control
                                    readOnly={this.state.submitting}
                                    name={'description'}
                                    type={'textarea'}
                                    onChange={(e) => this.handleChange(e)}
                                    cols={3}
                                />
                            </Form>
                        ) : (
                            <Form>
                                <Form.Group controlId="file.image">
                                    <Form.Label className={'required'}>
                                        {this.props.kedo.t('Files')}
                                    </Form.Label>
                                    <section
                                        className={
                                            'fakeInputField ' +
                                            (this.state.errors &&
                                            (!Array.isArray(
                                                this.state.fileData
                                            ) ||
                                                this.state.fileData.length ===
                                                    0)
                                                ? 'fake-feedback'
                                                : '')
                                        }
                                    >
                                        <input
                                            name={'files'}
                                            accept={
                                                this.props.accepts
                                                    ? this.getAccepts(
                                                          this.props.accepts
                                                      )
                                                    : undefined
                                            }
                                            onChange={
                                                this.handleMultipleFileChange
                                            }
                                            type="file"
                                            multiple
                                        />
                                    </section>
                                </Form.Group>

                                {this.state.multiple &&
                                    this.state.files.length > 0 && (
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <Form.Label>
                                                            {this.props.kedo.t(
                                                                'File Name'
                                                            )}
                                                        </Form.Label>
                                                    </th>
                                                    <th>
                                                        <Form.Label
                                                            className={
                                                                'required'
                                                            }
                                                        >
                                                            {this.props.kedo.t(
                                                                'New Name'
                                                            )}
                                                        </Form.Label>
                                                    </th>
                                                    <th>
                                                        <Form.Label>
                                                            {this.props.kedo.t(
                                                                'Description'
                                                            )}
                                                        </Form.Label>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.fileData.map(
                                                    (file, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <Form.Label>
                                                                    {
                                                                        file
                                                                            .file
                                                                            .name
                                                                    }
                                                                </Form.Label>
                                                            </td>
                                                            <td>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={
                                                                        file.name
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.handleFileNameChange(
                                                                            e,
                                                                            index
                                                                        )
                                                                    }
                                                                    isInvalid={
                                                                        this
                                                                            .state
                                                                            .errors[
                                                                            index
                                                                        ] &&
                                                                        this
                                                                            .state
                                                                            .errors[
                                                                            index
                                                                        ].name
                                                                    }
                                                                />
                                                                {this.state
                                                                    .errors[
                                                                    index
                                                                ] &&
                                                                this.state
                                                                    .errors[
                                                                    index
                                                                ].name ? (
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {
                                                                            this
                                                                                .state
                                                                                .errors[
                                                                                index
                                                                            ]
                                                                                .name
                                                                        }
                                                                    </Form.Control.Feedback>
                                                                ) : null}
                                                            </td>
                                                            <td>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={
                                                                        file.description
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.handleFileDescriptionChange(
                                                                            e,
                                                                            index
                                                                        )
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </Table>
                                    )}

                                {this.state.errors &&
                                this.state.errors.fileContent
                                    ? this.renderErrorFeedback(
                                          this.state.errors.fileContent
                                      )
                                    : null}
                            </Form>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.submitFile} variant="primary">
                            {this.state.submitting ? (
                                <LoadingDefault size={'sm'} />
                            ) : (
                                <FontAwesomeIcon icon={faSave} />
                            )}
                            &nbsp; {this.props.kedo.t('Save')}
                        </Button>
                        <Button
                            onClick={() => this.props.onClose()}
                            variant="secondary"
                        >
                            {this.props.kedo.t('Cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default NewFile
