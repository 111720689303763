import React from 'react'
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap'
import UserSelect from '../../../Elements/Select/UserSelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Datetime from 'react-datetime'
import moment from 'moment/moment'
import { faEnvelope } from '@fortawesome/pro-duotone-svg-icons'
import CreatableSelect from 'react-select/creatable'
import { faMobile } from '@fortawesome/pro-duotone-svg-icons/faMobile'
import { faRotate } from '@fortawesome/pro-duotone-svg-icons/faRotate'
import { toast } from 'react-toastify'

class ContentDossierSecureShare extends React.Component {
    constructor(props) {
        super(props)

        let shareUntilDate = moment().add(7, 'days')

        this.state = {
            showModal: true,
            processing: false,
            error: false,
            shareType: 'positions',
            shareUntilDate: shareUntilDate.format('DD-MM-YYYY'),
            shareTypeValue: null,
            step: 1,
            contacts: [],
            kedocxs: [],
            displayPositions: [],
            hasKedocxs: null,
            hasDisplayPositions: null,
            recipientUsers: [],
            dossierPersons: [],
            recipientDossierPersons: [],
            recipientCustomEmails: [],
            recipientCustomEmailsInput: '',
            settings: [],
        }
        this.nextStep = this.nextStep.bind(this)
        this.onChangeUsers = this.onChangeUsers.bind(this)
        this.transformContactsForPayload =
            this.transformContactsForPayload.bind(this)
        this.fetchModule = this.fetchModule.bind(this)
        this.fetchSecureShare = this.fetchSecureShare.bind(this)
    }

    renderSteps() {
        if (this.state.step === 2) {
            return this.renderStepTwo()
        } else if (this.state.step === 3) {
            return this.renderStepThree()
        }

        return this.renderStepOne()
    }

    nextStep(currentStep) {
        switch (currentStep) {
            case 1:
                this.setState({ step: currentStep + 1 })
                break
            case 2:
                this.setState({ step: currentStep + 1 })
                break
            case 3:
                this.setState({ step: currentStep + 1 })
                break
        }
    }

    handleCustomizeFieldChange(newValue) {
        this.setState({
            recipientCustomEmails: newValue === null ? [] : newValue,
        })
    }

    componentDidMount() {
        this.fetchModule()
        this.fetchKedocx()
        this.fetchPositions()
    }

    fetchModule() {
        const api = this.props.kedo.api()
        this.setState({ loading: true })

        const filters = { limit: 10, page: 0 }
        api.get(api.getModuleEndpoint(), { params: filters })
            .then((response) => {
                const envModuleNames = response.data.results
                    .map((item) => item.name)
                    .join(',')
                const envModuleParams = {
                    params: {
                        names: envModuleNames,
                        limit: 100,
                        environment: this.props.kedo.env().getEnvironment().id,
                    },
                }

                api.get(api.getEnvironmentModuleEndpoint(), envModuleParams)
                    .then((envModuleResponse) => {
                        const module = envModuleResponse.data.results
                        console.log(module)

                        this.fetchSecureShare(module)
                        this.setState({ loading: false })
                    })
                    .catch(() => this.setState({ loading: false }))
            })
            .catch(() => this.setState({ loading: false }))
    }

    async fetchSecureShare(data) {
        const api = this.props.kedo.api()
        const { contentDossier } = this.props

        try {
            for (const item of data) {
                if (item.module === 'secureshare') {
                    const response = await api.get(
                        api.getEnvironmentModuleEndpoint(item.id)
                    )
                    const settings = response.data.settings[0]

                    const params = {
                        defDossier: settings.child_def_dossier_id,
                        limit: 50,
                        page: 0,
                        embedded: contentDossier.id,
                        linkId: settings.linkId,
                    }

                    const dossierResponse = await api.get(
                        api.getContentDossierEndpoint(),
                        { params: params }
                    )

                    const dossierData = dossierResponse.data.results.contents

                    const filteredArray = dossierData
                        .filter((item) => {
                            return item.content.id === settings.email_field
                        })
                        .map((item) => ({
                            id: item.id,
                            content: item.content.content,
                        }))

                    this.setState({ dossierPersons: filteredArray })
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    process() {
        this.setState({ processing: true, error: false })
        const kedo = this.props.kedo
        const secureShareUrl = kedo
            .api()
            .getSecureShareEndpoint(this.props.contentDossier.id)

        let payload = {
            activeUntil: this.state.shareUntilDate,
            contacts: this.state.contacts,
            settings: {
                share_type: this.state.shareType,
                share_value: this.state.shareTypeValue,
            },
        }

        kedo.api()
            .post(secureShareUrl, payload)
            .then((response) => {
                toast.success(kedo.t('Information secure shared'), {
                    position: 'bottom-center',
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                this.setState(
                    {
                        processing: false,
                        error: false,
                    },
                    this.props.closeModal
                )
            })
            .catch((error) => {
                this.setState({
                    processing: false,
                    error: true,
                })
            })
    }

    onChangeUsers(values) {
        //TODO KeDo: Change this
        this.setState({ recipientUsers: values }, () =>
            this.transformContactsForPayload(
                values,
                this.state.recipientDossierPersons,
                this.state.recipientCustomEmails
            )
        )
    }

    handleKeyDown(event) {
        if (!this.state.recipientCustomEmailsInput) {
            return
        }

        const createOption = (label) => ({
            label,
            value: label,
        })

        let customizeEmail = this.state.recipientCustomEmails
        let str = ''
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                customizeEmail.push(
                    createOption(this.state.recipientCustomEmailsInput)
                )
                str = customizeEmail.map((option) => option.value)

                this.setState(
                    {
                        recipientCustomEmails: customizeEmail,
                        recipientCustomEmailsInput: '',
                    },
                    () => {
                        this.transformContactsForPayload(
                            this.state.recipientUsers,
                            this.state.recipientDossierPersons,
                            customizeEmail
                        )
                    }
                )

                event.preventDefault()
        }
    }

    renderStepOne() {
        const components = {
            DropdownIndicator: null,
        }

        const clearSelect = (value) => {
            this.setState({ recipientCustomEmails: [] })
        }

        const kedo = this.props.kedo

        return (
            <Row>
                <Col md={6}>
                    <fieldset>
                        <legend>
                            {kedo.t('Add recipients from dossier', {
                                ns: 'secureshare',
                            })}
                        </legend>
                        <Form.Group className="d-block">
                            {this.state.dossierPersons.map((personItem) => {
                                return (
                                    <div
                                        key={personItem.id}
                                        className="form-check"
                                    >
                                        <Form.Check
                                            type="checkbox"
                                            id={`checkbox-${personItem.id}`}
                                            value={personItem.content}
                                            label={personItem.content}
                                            className="mb-2"
                                        />
                                    </div>
                                )
                            })}
                        </Form.Group>

                        {this.state.dossierPersons.length <= 0 ? (
                            <Alert variant={'light'}>
                                {kedo.t('No items found')}
                            </Alert>
                        ) : null}
                    </fieldset>
                    <fieldset>
                        <legend>
                            {kedo.t('Add users', { ns: 'secureshare' })}
                        </legend>
                        <UserSelect
                            currentUsers={this.state.recipientUsers}
                            kedo={kedo}
                            onChangeUsers={this.onChangeUsers}
                        />
                    </fieldset>
                </Col>
                <Col md={6}>
                    <fieldset>
                        <legend>
                            {kedo.t('Add custom mail addresses', {
                                ns: 'secureshare',
                            })}
                        </legend>
                        <CreatableSelect
                            components={components}
                            inputValue={this.state.recipientCustomEmailsInput}
                            value={this.state.recipientCustomEmails}
                            isClearable
                            isMulti
                            menuIsOpen={false}
                            onChange={(newValue) =>
                                this.handleCustomizeFieldChange(newValue)
                            }
                            onClear={clearSelect}
                            onKeyDown={(e) => this.handleKeyDown(e)}
                            onInputChange={(newValue) =>
                                this.setState({
                                    recipientCustomEmailsInput: newValue,
                                })
                            }
                        />
                    </fieldset>
                </Col>
            </Row>
        )
    }

    fetchKedocx() {
        const kedo = this.props.kedo
        let params = {
            defDossier: this.props.contentDossier.def_dossier_id,
            limit: 300,
        }
        kedo.api()
            .get(kedo.api().getKedocxEndpoint(), { params: params })
            .then((response) => {
                this.setState({
                    kedocxs: response.data.results,
                    hasKedocxs: response.data.pager.items > 0,
                    shareType:
                        response.data.pager.items <= 0
                            ? 'positions'
                            : this.state.shareType,
                })
            })
    }

    renderTitle() {
        let mainTitle = this.props.kedo.t('Secure share data', {
            ns: 'secureshare',
        })

        if (this.state.step === 1) {
            return mainTitle + ': ' + this.props.kedo.t('Recipients')
        } else if (this.state.step === 2) {
            return mainTitle + ': ' + this.props.kedo.t('Configuration')
        } else if (this.state.step === 3) {
            return mainTitle + ': ' + this.props.kedo.t('Summary')
        }

        return mainTitle
    }

    renderButtonBar() {
        const kedo = this.props.kedo
        return (
            <Row>
                <Col md={12}>
                    <Button
                        disabled={this.state.processing}
                        className={'mr-2'}
                        onClick={this.props.closeModal}
                    >
                        {kedo.t('Cancel')}
                    </Button>
                    {this.state.step > 1 ? (
                        <Button
                            variant={'secondary'}
                            className={'ml-2'}
                            disabled={this.state.processing}
                            onClick={() =>
                                this.setState({ step: this.state.step - 1 })
                            }
                        >
                            {kedo.t('Previous')}
                        </Button>
                    ) : null}
                    {this.state.step <= 2 ? (
                        <Button
                            variant={'secondary'}
                            disabled={
                                this.state.processing ||
                                (this.state.step <= 2 &&
                                    this.state.contacts.length <= 0 &&
                                    this.state.recipientUsers.length <= 0)
                            }
                            className={'ml-2'}
                            onClick={() => this.nextStep(this.state.step)}
                        >
                            {kedo.t('Next')}
                        </Button>
                    ) : null}
                    {this.state.step === 3 ? (
                        <Button
                            className={'ml-2'}
                            disabled={this.state.processing}
                            onClick={() => this.process()}
                        >
                            <span className={'mr-2'}>{kedo.t('Send')}</span>
                            <FontAwesomeIcon
                                spin={this.state.processing}
                                icon={
                                    this.state.processing
                                        ? faRotate
                                        : faEnvelope
                                }
                            />
                        </Button>
                    ) : null}
                </Col>
            </Row>
        )
    }

    renderStepTwo() {
        const kedo = this.props.kedo

        const monthsAgo = moment()

        const isValidUntilDate = (current) => {
            return current.isAfter(monthsAgo)
        }

        return (
            <Row>
                <Col md={6}>
                    <strong>
                        {kedo.t('step_two_title', { ns: 'secureshare' })}
                    </strong>
                    <p>
                        {kedo.t('step_two_description', { ns: 'secureshare' })}
                    </p>
                </Col>
                <Col md={6}>
                    <fieldset>
                        <legend>
                            {kedo.t('What do you want to share?', {
                                ns: 'secureshare',
                            })}
                        </legend>
                        <Form.Group>
                            <Form.Check
                                name="share_type"
                                type={'radio'}
                                checked={this.state.shareType === 'positions'}
                                value={'positions'}
                                onChange={(e) =>
                                    this.setState({ shareType: e.target.value })
                                }
                                label={kedo.t('Data position of this dossier', {
                                    ns: 'secureshare',
                                })}
                            />
                            <Form.Check
                                name="share_type"
                                type={'radio'}
                                disabled={this.state.hasKedocxs === false}
                                checked={this.state.shareType === 'kedocx'}
                                value={'kedocx'}
                                onChange={(e) =>
                                    this.setState({ shareType: e.target.value })
                                }
                                label={kedo.t('Template (kedocx)', {
                                    ns: 'secureshare',
                                })}
                            />
                        </Form.Group>
                        {this.state.shareType === 'positions' ? (
                            <Form.Group>
                                <Form.Label>
                                    {kedo.t('Select position', {
                                        ns: 'secureshare',
                                    })}
                                </Form.Label>
                                <Form.Control
                                    value={this.state.shareTypeValue}
                                    as={'select'}
                                    onChange={(e) =>
                                        this.setState({
                                            shareTypeValue: e.target.value,
                                        })
                                    }
                                >
                                    <option value={''}>
                                        {kedo.t('Choose an option')}
                                    </option>
                                    {this.state.displayPositions.map(
                                        (dpItem) => {
                                            return (
                                                <option
                                                    key={`share-dp-${dpItem.id}`}
                                                    value={dpItem.id}
                                                >
                                                    {kedo.translateItem(
                                                        dpItem,
                                                        'displayposition'
                                                    )}
                                                </option>
                                            )
                                        }
                                    )}
                                </Form.Control>
                            </Form.Group>
                        ) : null}
                        {this.state.shareType === 'kedocx' ? (
                            <Form.Group>
                                <Form.Label>
                                    {kedo.t('Select kedocx')}
                                </Form.Label>
                                <Form.Control
                                    value={this.state.shareTypeValue}
                                    as={'select'}
                                    onChange={(e) =>
                                        this.setState({
                                            shareTypeValue: e.target.value,
                                        })
                                    }
                                >
                                    <option value={''}>
                                        {kedo.t('Choose an option')}
                                    </option>
                                    {this.state.kedocxs.map((kedocxItem) => {
                                        return (
                                            <option
                                                key={`share-kedocx-${kedocxItem.id}`}
                                                value={kedocxItem.id}
                                            >
                                                {kedocxItem.name}
                                            </option>
                                        )
                                    })}
                                </Form.Control>
                            </Form.Group>
                        ) : null}
                    </fieldset>
                    <fieldset>
                        <legend>
                            {kedo.t('How long is the link valid?', {
                                ns: 'secureshare',
                            })}
                        </legend>
                        <Form.Group>
                            <Datetime
                                closeOnSelect
                                timeFormat={false}
                                value={this.state.shareUntilDate}
                                isValidDate={isValidUntilDate}
                                onChange={(e) => {
                                    this.setState({ shareUntilDate: e })
                                }}
                            />
                        </Form.Group>
                    </fieldset>
                </Col>
            </Row>
        )
    }
    renderStepThree() {
        const kedo = this.props.kedo

        return (
            <Row>
                <Col md={8}>
                    <strong>{kedo.t('Preview')}</strong>
                    <p>
                        {kedo.t('step_three_description', {
                            ns: 'secureshare',
                        })}
                    </p>
                </Col>
                <Col md={4}>
                    <strong>{kedo.t('Recipients')}</strong>
                    <ul>
                        {this.state.contacts.map((recipient) => {
                            return (
                                <li key={recipient.email}>
                                    {recipient.email}{' '}
                                    {recipient.mobile ? (
                                        <FontAwesomeIcon icon={faMobile} />
                                    ) : null}
                                </li>
                            )
                        })}
                    </ul>
                </Col>
            </Row>
        )
    }

    onClose() {
        this.props.closeModal()
    }

    render() {
        const kedo = this.props.kedo
        return (
            <Modal
                show={this.state.showModal}
                onHide={this.props.closeModal}
                size={'xl'}
                keyboard={false}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.renderTitle()}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.error ? (
                        <Alert variant={'danger'}>
                            {kedo.t('Something went wrong')}
                        </Alert>
                    ) : null}
                    {this.renderSteps()}
                </Modal.Body>
                <Modal.Footer>{this.renderButtonBar()}</Modal.Footer>
            </Modal>
        )
    }

    fetchPositions() {
        const kedo = this.props.kedo
        let params = {
            defDossier: this.props.contentDossier.def_dossier_id,
            limit: 300,
        }
        kedo.api()
            .get(kedo.api().getDisplayPositionEndpoint(), { params: params })
            .then((response) => {
                this.setState({
                    displayPositions: response.data.results,
                    hasDisplayPositions: response.data.pager.items > 0,
                    shareTypeValue:
                        response.data.pager.items > 0 &&
                        response.data.results.find(
                            (dpItem) => dpItem.type === 'main_content'
                        )
                            ? response.data.results.find(
                                  (dpItem) => dpItem.type === 'main_content'
                              ).id
                            : this.state.shareTypeValue,
                })
            })
    }

    transformContactsForPayload(
        recipientUsers,
        recipientDossierPersons,
        recipientCustomEmails
    ) {
        let contacts = []

        recipientUsers.map((recipient) => {
            this.props.kedo
                .api()
                .get(this.props.kedo.api().getUserEndpoint(recipient.id))
                .then((response) => {
                    contacts.push({
                        email: response.data.email,
                        mobile: response.data.mobile,
                    })
                })
        })

        if (
            recipientCustomEmails !== null &&
            Array.isArray(recipientCustomEmails)
        ) {
            recipientCustomEmails.map((recipient) => {
                contacts.push({
                    email: recipient.value,
                })
            })
        }

        this.setState({ contacts: contacts })
    }
}

export default ContentDossierSecureShare
