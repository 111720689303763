import React from 'react'
import {
    Badge,
    Col,
    FormControl,
    InputGroup,
    ListGroup,
    Row,
    Button,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LoadingDefault from '../Elements/Loading/LoadingDefault'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes'
import { faTrashCan } from '@fortawesome/pro-duotone-svg-icons/faTrashCan'

import { slide as Menu } from 'react-burger-menu'
import Pagination from '../Elements/Pagination'
import ItemCounter from '../Elements/ItemCounter'

class Favorites extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [],
            limit: 10,
            page: 0,
            pager: {},
            menuWidth: '16.67%',
            search: '',
            showSearch: true,
            favoritesRef: React.createRef(),
            searchRef: React.createRef(),
        }

        this.fetchItems = this.fetchItems.bind(this)
        this.onChangePage = this.onChangePage.bind(this)
        this.onKeyUp = this.onKeyUp.bind(this)
        this.onChange = this.onChange.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.setMenuWidth = this.setMenuWidth.bind(this)
        this.stateHandle = this.stateHandle.bind(this)
        this.resetSearch = this.resetSearch.bind(this)
    }

    fetchItems() {
        const kedo = this.props.kedo
        if (this.state.loading) {
            return
        }
        let url = kedo.api().getContentDossierFavoriteEndpoint()
        this.setState({ loading: true, items: [] })

        let params = {
            params: {
                environment: kedo.env().getEnvironment().id,
                limit: this.state.limit,
                page: this.state.page,
            },
        }

        if (this.state.search) {
            params.params.search = this.state.search
        }

        kedo.api()
            .get(url, params)
            .then((response) =>
                this.setState({
                    loading: false,
                    loaded: true,
                    show: true,
                    items: response.data.results,
                    pager: response.data.pager,
                })
            )
            .catch((error) =>
                this.setState({
                    loading: false,
                    loaded: true,
                })
            )
    }

    onChange(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    onKeyUp() {
        if (this.state.timeout) clearTimeout(this.state.timeout)
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.timeout = setTimeout(() => {
            this.fetchItems()
        }, 200)
    }

    shortenText(text) {
        let dotsNeeded = text.length >= 50
        let trimmedString = text.substr(0, 50)

        trimmedString = trimmedString.substr(
            0,
            Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
        )
        trimmedString = dotsNeeded ? trimmedString + '...' : trimmedString

        return trimmedString
    }

    getDate(item) {
        if (!item.created_at) {
            return
        }

        let date = new Date(item.created_at)
        let dateFormat = new Intl.DateTimeFormat('nl-NL', {
            dateStyle: 'medium',
            timeStyle: 'medium',
        })

        return (
            <small
                style={{ fontSize: '9px' }}
                className={'d-flex flex-row justify-content-end'}
            >
                {dateFormat.format(date)}
            </small>
        )
    }

    getDefDossierName(item, defDossiers, selectedLocale) {
        if (!selectedLocale) {
            return
        }

        let defDossier = defDossiers.find(
            (ddItem) => ddItem.id === item.def_dossier_id
        )
        if (
            !defDossier ||
            !defDossier.translations ||
            defDossier.translations.length <= 0
        ) {
            return
        }

        let translation = defDossier.translations.find(
            (trItem) => trItem.culture === selectedLocale.code
        )

        return (
            <Link
                onClick={() => {
                    this.props.closeSidebar()
                }}
                to={`/defdossier/${item.def_dossier_id}`}
            >
                <Badge variant="primary">
                    {translation && translation.name_plural
                        ? translation.name_plural
                        : item.def_dossier_id}
                </Badge>
            </Link>
        )
    }

    deleteFavorite(data) {
        let url = this.props.kedo
            .api()
            .getContentDossierFavoriteEndpoint(data.content_dossier_id)
        this.setState({ loading: true })
        this.props.kedo
            .api()
            .delete(url)
            .then(
                (response) => this.fetchItems(),
                this.setState({
                    loading: false,
                })
            )
    }

    renderItems(kedo) {
        let defDossiers = []
        let selectedLocale = null
        if (this.state.items.length > 0) {
            defDossiers = kedo.env().getCurrentEnvironmentDefDossiers()
            selectedLocale = kedo.env().getSelectedLocale()
        }

        if (this.state.loading) {
            return (
                <ListGroup variant="flush" className={'quickSearchItemlist'}>
                    <ListGroup.Item>
                        <LoadingDefault
                            as={'span'}
                            size={'sm'}
                            message={kedo.t('Loading')}
                        />
                    </ListGroup.Item>
                </ListGroup>
            )
        }

        return (
            <ListGroup
                key={`favorites`}
                variant="flush"
                className={' list-group-flush'}
            >
                {this.state.items.map((item) => (
                    <React.Fragment key={item.id}>
                        <ListGroup.Item
                            className="position-relative"
                            href={`/contentdossier/${item.content_dossier_id}`}
                            onClick={() => {
                                this.props.closeSidebar()
                            }}
                            action
                        >
                            <div className={'d-flex flex-row quickSearchPills'}>
                                {this.getDefDossierName(
                                    item,
                                    defDossiers,
                                    selectedLocale
                                )}
                            </div>
                            <strong>{item.summary}</strong>
                            {this.getDate(item)}
                            <Button
                                size={'sm'}
                                variant={'primary'}
                                className="trash-icon"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    this.deleteFavorite(item)
                                }}
                            >
                                <div>
                                    <FontAwesomeIcon icon={faTrashCan} />
                                </div>
                            </Button>
                        </ListGroup.Item>
                    </React.Fragment>
                ))}
                {this.state.items.length === 0 ? (
                    <ListGroup.Item variant="warning">
                        <FontAwesomeIcon className={'mr-2'} icon={faTimes} />{' '}
                        {kedo.t('No items found')}
                    </ListGroup.Item>
                ) : null}
            </ListGroup>
        )
    }

    setMenuWidth() {
        if (window.innerWidth > 576) {
            this.setState({
                menuWidth: '16,67%',
            })
        } else {
            this.setState({
                menuWidth: '100%',
            })
        }
    }

    onChangePage(page) {
        this.setState({ page: page }, this.fetchItems)
    }

    renderFooter() {
        return (
            <Row>
                <Col xs={12}>
                    <Pagination
                        pager={this.state.pager}
                        onChangePage={this.onChangePage}
                    />
                    <ItemCounter pager={this.state.pager} />
                </Col>
            </Row>
        )
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick)

        this.setMenuWidth()
    }

    handleClick(e) {
        if (this.props.open) {
            if (!this.state.favoritesRef.current.contains(e.target)) {
                //document.body.style.overflow = "";
                this.props.closeSidebar()
            }
        }
    }

    resetSearch() {
        this.setState(
            {
                search: '',
                items: [],
                page: 0,
            },
            this.fetchItems
        )
    }

    stateHandle(newState) {
        if (!newState) {
            this.resetSearch()
        } else {
            this.fetchItems()
        }
    }

    render() {
        const kedo = this.props.kedo

        return (
            <div id="sidebar" ref={this.state.favoritesRef}>
                <Menu
                    id="sidebar-favorite"
                    right
                    isOpen={this.props.open}
                    onStateChange={(newState) => {
                        this.props.handleSidebar(newState)
                        this.stateHandle(newState)
                    }}
                    customBurgerIcon={false}
                    width={this.state.menuWidth}
                    noOverlay
                >
                    <div>
                        <div className="sidebar-header">
                            <h3>{kedo.t('Favorites')}</h3>
                        </div>
                        <div className="content">
                            <Row>
                                <Col xs={12} className="UpperSearchField">
                                    <InputGroup>
                                        <FormControl
                                            autoComplete="off"
                                            className={'form-control'}
                                            name={'search'}
                                            onChange={this.onChange}
                                            onKeyUp={this.onKeyUp}
                                            placeholder={
                                                kedo.t('Quick Search') + '...'
                                            }
                                            type={'text'}
                                            value={this.state.search}
                                            ref={this.state.searchRef}
                                        />
                                        <InputGroup.Append>
                                            {this.state.search.length > 0 ? (
                                                <button
                                                    className="btn bg-transparent exitQuickSearch"
                                                    onClick={this.resetSearch}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTimes}
                                                    />
                                                </button>
                                            ) : null}
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Row>
                            {this.state.items.length > 0
                                ? this.renderItems(kedo)
                                : null}
                            {this.renderFooter()}
                        </div>
                    </div>
                </Menu>
            </div>
        )
    }
}

export default Favorites
