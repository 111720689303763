import { useState, useCallback, useEffect } from 'react'
import { useHistory, Blocker } from 'react-router-dom'
export interface ConfirmationModalProps {
    title: string
    content: string
    confirmBtnLabel: string
    closeBtnLabel: string
    onClose?: () => void
    onConfirm?: (href?: any) => void
}

export const useUnsavedChangesModal = (config: ConfirmationModalProps) => {
    const [isVisible, setIsVisible] = useState<boolean>(false)
    const [blockLocation, setBlockLocation] = useState<boolean>(false)
    const [modalConfig, setModalConfig] = useState<ConfirmationModalProps>({
        title: 'Confirmation',
        content: 'You have changes',
        confirmBtnLabel: 'Yes',
        closeBtnLabel: 'Cancel',
        onClose: () => setIsVisible(false),
        onConfirm: () => setIsVisible(false),
        ...config,
    })
    const history = useHistory()
    const showModal = useCallback(
        (onConfirm: () => void, onClose: () => void) => {
            setModalConfig({
                ...modalConfig,
                onClose: () => {
                    onClose?.()
                    setIsVisible(false)
                },
                onConfirm: () => {
                    onConfirm?.()
                    setIsVisible(false)
                },
            })
            setIsVisible(true)
        },
        [modalConfig]
    )

    useEffect(() => {
        const unblock: Blocker = history.block((location) => {
            if (!isVisible && blockLocation) {
                showModal(() => {
                    setBlockLocation(false)
                    modalConfig.onConfirm()
                }, modalConfig.onClose)
                return false
            }
            return true
        })

        return () => unblock()
    }, [history, isVisible, blockLocation, showModal])

    return { showModal, setBlockLocation, isVisible, modalConfig }
}
