import {
    Alert,
    Button,
    ButtonGroup,
    Dropdown,
    DropdownButton,
    Form,
    FormControl,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faBell } from '@fortawesome/pro-duotone-svg-icons/faBell'
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle'
import { faSort } from '@fortawesome/pro-duotone-svg-icons/faSort'
import { faSortDown } from '@fortawesome/pro-duotone-svg-icons/faSortDown'
import { faSortUp } from '@fortawesome/pro-duotone-svg-icons/faSortUp'
import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes'

import * as React from 'react'
import AdminQuickEditMenu from '../../../Components/Elements/AdminQuickEditMenu.jsx'
import ShowEntries from '../../../Components/Elements/ShowEntries.jsx'
import MultiContentDossierArchive from '../../../Components/Pages/DefDossier/DefDossierToolbar/MultiContentDossierArchive'
import MultiContentDossierSubscribe from '../../../Components/Pages/DefDossier/DefDossierToolbar/MultiContentDossierSubscribe.jsx'
import MultiContentDossierFavorite from '../../../Components/Pages/DefDossier/DefDossierToolbar/MultiContentDossierFavorite.jsx'
import MultiContentDossierDelete from '../../../Components/Pages/DefDossier/DefDossierToolbar/MultiContentDossierDelete.jsx'
import MultiContentDossierMerge from '../../../Components/Pages/DefDossier/MergeDossier/index'
import MultiContentDossierMassUpdate from '../../../Components/Pages/DefDossier/DefDossierToolbar/MultiContentDossierMassUpdate.jsx'
import ContentDossierExport from '../../../Components/Pages/DefDossier/DefDossierToolbar/ContentDossierExport.jsx'
import DisplayItemHelper from '../../../util/DisplayItemHelper'
import { faObjectSubtract } from '@fortawesome/pro-duotone-svg-icons/faObjectSubtract'
import { RefObject, useEffect, useRef, useState } from 'react'
import { faBars } from '@fortawesome/pro-duotone-svg-icons'
import { faFolder } from '@fortawesome/pro-duotone-svg-icons/faFolder'
import { faStar } from '@fortawesome/pro-duotone-svg-icons/faStar'
import { faBellSlash } from '@fortawesome/pro-duotone-svg-icons/faBellSlash'
import { faPenToSquare } from '@fortawesome/pro-duotone-svg-icons/faPenToSquare'
import { faFileExport } from '@fortawesome/pro-duotone-svg-icons/faFileExport'
import { faTrash } from '@fortawesome/pro-duotone-svg-icons/faTrash'
import DefaultGrid from '../../../Components/Grid/DefaultGrid.jsx'
import { IDisplayPositionList } from '../../../context/EntityServiceContext'
import { useEntityService } from '../../../context/EntityServiceContext'
import Loading from '../../../Components/Elements/Loading/LoadingData.jsx'
import LoadingDefault from '../../../Components/Elements/Loading/LoadingDefault.jsx'
import { faSave } from '@fortawesome/pro-duotone-svg-icons/faSave'
import EntityHelper from '../../../util/EntityHelper'
import { toast } from 'react-toastify'
import { ToastOptions } from 'react-toastify/dist/types'

interface ContentDossierIndexHeaderProps {
    props: any
    kedo: any
    pager: any
    sort: any
    limit: any
    clearSort: any
    applySort: any
    handleEntriesChange: any
    mode: string
    loadingContent: boolean
    onDeleteSuccess: any
    getSearchFilter: any
    dossiers: Array<any>
    subscriptions: Array<any>
    favorites: Array<any>
    listItems: Array<IDisplayField>
    actionBtns: Array<any>
    fetchDossierResults: any
    selected: Array<any>
    defDossierId: number
    showArchived: boolean
    setSelected: any
    contents: any
    rowInput: any
    searchParams: any
    dossierQuery: any
    refreshResults: any
}

const ContentDossierIndexHeader: React.FC<ContentDossierIndexHeaderProps> = ({
    props,
    kedo,
    pager,
    loadingContent,
    sort,
    limit,
    clearSort,
    applySort,
    handleEntriesChange,
    mode,
    onDeleteSuccess,
    getSearchFilter,
    subscriptions,
    favorites,
    dossiers,
    listItems,
    actionBtns,
    fetchDossierResults,
    refreshResults,
    selected,
    rowInput,
    defDossierId,
    showArchived,
    setSelected,
    contents,
    searchParams,
    dossierQuery,
}) => {
    if (!listItems) {
        return
    }

    const [showMergeModal, setShowMergeModal] = useState(false)
    const [rowInputInitialized, setRowInputInitialized] = useState(false)
    const [showActionModal, setShowActionModal] = useState('')
    const [rowInputContent, setRowInputContent] = useState({})
    const [rowInputErrors, setRowInputErrors] = useState({})
    const [rowInputDd, setRowInputDd] = useState(null)
    const [rowInputValues, setRowInputValues] = useState([])
    const [rowInputItems, setRowInputItems] = useState([])
    const [loadingRowInput, setLoadingRowInput] = useState(false)
    const [rowInputSubmitting, setRowInputSubmitting] = useState(false)
    let curRef = useRef(null)
    const entityService = useEntityService()

    const getCurrentRef = (): RefObject<any> => {
        if (!curRef) {
            curRef = React.createRef()
        }

        return curRef
    }

    const checkAll = (event) => {
        if (!dossiers) {
            return
        }

        if (true === event.target.checked) {
            setSelected(dossiers.map((item) => item.id))
        } else {
            setSelected([])
        }
    }

    const isSortable = (listField): boolean => {
        if (
            listField &&
            listField.settings &&
            listField.settings.dossier_data &&
            (listField.settings.dossier_data === 'created_at' ||
                listField.settings.dossier_data === 'modified_at')
        ) {
            return true
        }

        if (!listField || !listField.def_dossier_def_field) {
            return false
        }

        if (
            DisplayItemHelper.isTypeOfField(
                listField,
                DisplayItemHelper.FIELD_TEXTAREA_TYPE
            )
        ) {
            return false
        }

        return true
    }

    const isAllowedDd = (credential: string): boolean => {
        return kedo
            .env()
            .isAllowedDefDossier(credential, defDossierId, kedo.user())
    }

    const selectedDossiers = dossiers.filter((item) =>
        selected.find((selectedId) => selectedId === item.id)
    )
    const archivedDossiers =
        showArchived && selectedDossiers.find((item) => item.archived === true)
    const dearchivedDossiers =
        showArchived && selectedDossiers.find((item) => item.archived === false)

    let extraColumns = 3
    if (actionBtns.length > 0) {
        extraColumns = 4
    }

    const hasSubscriptions = subscriptions && subscriptions.length > 0

    if (loadingContent) {
        return
    }

    const checkRowInputErrors = (errors) => {
        const values = []
        const newItems = []
        rowInputItems
            .filter((item) => item.view === 'edit' || item.view === 'show/edit')
            .map((item) => newItems.push(item))

        newItems.map((filterItem) => {
            values.push({
                id: filterItem.id,
                type: filterItem.def_dossier_def_field
                    ? filterItem.def_dossier_def_field.def_field.type
                    : filterItem.def_dossier_link,
                error: errors ? errors[filterItem.id] : errors[filterItem.id],
            })
        })

        const focusItem = values.find((item) => item.error)

        if (focusItem) {
            return focusItem.id
        }
    }

    const addRowInputDossier = () => {
        setRowInputSubmitting(true)

        const values = getCurrentRef().current.getValues()
        if (props.embedded && props.embedded !== true) {
            values['embedded'] = props.embedded
            values['linkId'] = props.linkId
        }

        values.defDossier = rowInputDd.id

        const customPosition = {
            id: null,
            display_items: rowInputItems,
        }

        const submitErrors = EntityHelper.checkCanSubmitDisplayPosition(
            kedo,
            rowInputDd,
            customPosition,
            getCurrentRef(),
            values,
            false
        )

        if (Object.keys(submitErrors).length > 0) {
            setRowInputErrors(submitErrors)
            setRowInputSubmitting(false)
            const errorId = checkRowInputErrors(submitErrors)
            getCurrentRef().current.doFocus(errorId)
            return
        }

        const saveUrl =
            kedo.api().getContentDossierEndpoint() + '?customView=row_input'
        const toastOptions = {}

        kedo.api()
            .post(saveUrl, values)
            .then((response) => {
                setRowInputContent({})
                setRowInputSubmitting(false)
                setRowInputErrors([])
                getRowInputDisplayFields()
                if (refreshResults) {
                    refreshResults()
                }
                toast.success(
                    kedo.t('dossier_errors.success_create', {
                        defdossier: kedo
                            .env()
                            .translateItem(rowInputDd, 'defdossier'),
                    }),
                    toastOptions
                )
            })
            .catch((error) => {
                if (error.response && error.response.status === 409) {
                    setRowInputErrors(error.response.data.errors)
                    setRowInputSubmitting(false)
                } else if (error.response && error.response.status === 403) {
                    //setDenied_error(kedo.t('not_allowed_to_create_dossier'))
                    setRowInputSubmitting(false)

                    toast.error(
                        kedo.t('dossier_errors.permission_create'),
                        toastOptions
                    )
                    window.scrollTo(0, 0)
                } else {
                    toast.error(
                        kedo.t('dossier_errors.error_create'),
                        toastOptions
                    )
                    setRowInputErrors(error.response.data.errors)
                    setRowInputSubmitting(false)

                    const errorId = checkRowInputErrors(false)
                    getCurrentRef().current.doFocus(errorId)
                }
            })
    }

    const getRowInputDisplayFields = async () => {
        setLoadingRowInput(true)
        const ddiParams = {
            view: 'row_input',
            defDossier: props.item.def_dossier_link.child_def_dossier_id,
            sort: ['rank', 'col'],
            sortOrder: ['ASC', 'ASC'],
        }

        const displayItems = await entityService.getDisplayFields(
            props.item.def_dossier_link.child_def_dossier_id,
            ddiParams
        )
        const inputDd = await entityService.getEntity(
            props.item.def_dossier_link.child_def_dossier_id
        )
        setRowInputDd(inputDd)

        const newDisplayItems = []
        for (let i = 0; i < displayItems.results.length; i++) {
            const clone = { ...displayItems.results[i] }
            clone.view = 'edit'
            newDisplayItems.push(clone)
        }

        setRowInputItems(newDisplayItems)
        setLoadingRowInput(false)
    }

    if (rowInput && !rowInputInitialized && defDossierId) {
        setRowInputInitialized(true)
        getRowInputDisplayFields()
    }

    const onRowInputChangeValue = (itemId, value, item) => {
        setRowInputValues(value)
    }

    if (loadingRowInput) {
        return (
            <thead>
                <th colSpan={listItems.length + extraColumns + 1} />
            </thead>
        )
    }

    return (
        <thead>
            {rowInput === true &&
            rowInputInitialized &&
            rowInputItems.length > 0 ? (
                <tr>
                    <th colSpan={listItems.length + extraColumns + 1}>
                        <div>
                            {!rowInputInitialized ||
                            (loadingRowInput && rowInputItems.length <= 0) ? (
                                <Loading />
                            ) : (
                                <DefaultGrid
                                    changeValue={onRowInputChangeValue}
                                    conditions={[]}
                                    content={rowInputContent}
                                    embedded={props.embedded}
                                    linkId={props.linkId}
                                    errors={rowInputErrors}
                                    hiddenfields={[]}
                                    items={rowInputItems}
                                    kedo={kedo}
                                    onKeyPress={(item, keyEvent) => {
                                        if (keyEvent.key === 'Enter') {
                                            addRowInputDossier()
                                        }
                                    }}
                                    custom_layout={'row_input'}
                                    mode={'edit'}
                                    ref={getCurrentRef()}
                                    customButtons={() => {
                                        return (
                                            <Button
                                                disabled={rowInputSubmitting}
                                                type="submit"
                                                onClick={() =>
                                                    addRowInputDossier()
                                                }
                                                title="Create"
                                                variant="primary"
                                            >
                                                {rowInputSubmitting ? (
                                                    <LoadingDefault
                                                        size={'sm'}
                                                        as={'span'}
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faSave}
                                                    />
                                                )}
                                                {kedo.t('Add')}
                                            </Button>
                                        )
                                    }}
                                />
                            )}
                        </div>
                    </th>
                </tr>
            ) : null}
            <tr>
                <th colSpan={listItems.length + extraColumns + 1}>
                    <div
                        className={`d-flex ${
                            selected.length
                                ? 'justify-content-between'
                                : 'justify-content-end'
                        } align-items-center`}
                    >
                        {selected.length > 0 ? (
                            <ButtonGroup size={'sm'}>
                                {isAllowedDd('delete') ? (
                                    <Button
                                        size={'sm'}
                                        className={
                                            'd-flex justify-content-around align-items-center'
                                        }
                                        onClick={(event) => {
                                            event.preventDefault()
                                            if (selectedDossiers.length <= 0) {
                                                alert(
                                                    kedo.t(
                                                        'Select dossiers first'
                                                    )
                                                )
                                                return
                                            }
                                            setShowActionModal('delete')
                                        }}
                                        title={kedo.t('Delete')}
                                        variant={'outline-secondary'}
                                    >
                                        <FontAwesomeIcon
                                            className={'mr-2'}
                                            icon={faTrash}
                                        />
                                        <span>{kedo.t('Delete')}</span>
                                    </Button>
                                ) : null}
                                {isAllowedDd('export') ||
                                isAllowedDd('export_word') ||
                                isAllowedDd('export_csv') ||
                                isAllowedDd('export_pdf') ||
                                isAllowedDd('export_html') ? (
                                    <Button
                                        size={'sm'}
                                        className={
                                            'd-flex justify-content-around align-items-center'
                                        }
                                        onClick={(event) => {
                                            event.preventDefault()
                                            if (selectedDossiers.length <= 0) {
                                                alert(
                                                    kedo.t(
                                                        'Select dossiers first'
                                                    )
                                                )
                                                return
                                            }
                                            setShowActionModal('export')
                                        }}
                                        title={kedo.t('Export')}
                                        variant={'outline-secondary'}
                                    >
                                        <FontAwesomeIcon
                                            className={'mr-2'}
                                            icon={faFileExport}
                                        />
                                        {kedo.t('Export')}
                                    </Button>
                                ) : null}
                                <Dropdown
                                    onSelect={(eventKey, event) => {
                                        event.preventDefault()
                                        if (selectedDossiers.length <= 0) {
                                            alert(
                                                kedo.t('Select dossiers first')
                                            )
                                            return
                                        }

                                        switch (eventKey) {
                                            default:
                                                setShowActionModal(eventKey)
                                                break
                                        }
                                    }}
                                    as={ButtonGroup}
                                >
                                    <Dropdown.Toggle
                                        title={kedo.t('More actions')}
                                        variant={'outline-secondary'}
                                        id="dropdown-custom-components"
                                    >
                                        <FontAwesomeIcon
                                            className={'mr-2'}
                                            icon={faBars}
                                        />{' '}
                                        {kedo.t('More actions')}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="subscribe">
                                            <FontAwesomeIcon
                                                className={'mr-2'}
                                                icon={faBell}
                                            />
                                            {kedo.t('Subscription')}
                                        </Dropdown.Item>
                                        {hasSubscriptions === true ? (
                                            <Dropdown.Item eventKey="stopsubscription">
                                                <FontAwesomeIcon
                                                    className={'mr-2'}
                                                    icon={faBellSlash}
                                                />
                                                {kedo.t('Stop subscription')}
                                            </Dropdown.Item>
                                        ) : null}
                                        <Dropdown.Item eventKey="favorite">
                                            <FontAwesomeIcon
                                                className={'mr-2'}
                                                icon={faStar}
                                            />
                                            {kedo.t('Favorite')}
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="removefavorite">
                                            <FontAwesomeIcon
                                                className={'mr-2'}
                                                icon={faStar}
                                            />
                                            {kedo.t('Remove favorite')}
                                        </Dropdown.Item>
                                        {isAllowedDd('merge') &&
                                        selected.length === 2 ? (
                                            <Dropdown.Item eventKey="merge">
                                                <FontAwesomeIcon
                                                    className={'mr-2'}
                                                    icon={faObjectSubtract}
                                                />
                                                {kedo.t('Merge')}
                                            </Dropdown.Item>
                                        ) : null}
                                        {isAllowedDd('massupdate') ? (
                                            <Dropdown.Item
                                                disabled={true}
                                                eventKey="massupdate"
                                            >
                                                <FontAwesomeIcon
                                                    className={'mr-2'}
                                                    icon={faPenToSquare}
                                                />
                                                {kedo.t('Mass update')}
                                            </Dropdown.Item>
                                        ) : null}
                                        {dearchivedDossiers &&
                                        isAllowedDd('archive') ? (
                                            <Dropdown.Item eventKey="archive">
                                                <FontAwesomeIcon
                                                    className={'mr-2'}
                                                    icon={faFolder}
                                                />
                                                {kedo.t('Archive')}
                                            </Dropdown.Item>
                                        ) : null}
                                        {archivedDossiers &&
                                        isAllowedDd('archive') ? (
                                            <Dropdown.Item eventKey="dearchive">
                                                <FontAwesomeIcon
                                                    className={'mr-2'}
                                                    icon={faFolder}
                                                />
                                                {kedo.t('Dearchive')}
                                            </Dropdown.Item>
                                        ) : null}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </ButtonGroup>
                        ) : null}
                        <div className={''}>
                            {props.multiAdd ? (
                                <>{props.multiAdd}&nbsp;</>
                            ) : null}
                            {(mode === 'embeddedList' || mode === 'block') &&
                            props.addDossier ? (
                                <>{props.addDossier}&nbsp;</>
                            ) : null}
                            {mode === 'embeddedList' && props.filterDossier ? (
                                <>{props.filterDossier}&nbsp;</>
                            ) : null}
                            {isAllowedDd('export') &&
                            dossiers?.length > 0 &&
                            selected?.length <= 0 ? (
                                <Button
                                    onClick={(event) => {
                                        event.preventDefault()
                                        setShowActionModal('all-export')
                                    }}
                                    title={kedo.t('Export')}
                                    variant={'outline-secondary'}
                                >
                                    <FontAwesomeIcon
                                        className={'mr-2'}
                                        icon={faFileExport}
                                    />
                                </Button>
                            ) : null}
                            {dossiers?.length > 0 && selected?.length <= 0 ? (
                                <>
                                    <ShowEntries
                                        pager={pager}
                                        value={limit}
                                        onChange={handleEntriesChange}
                                    />
                                    &nbsp;
                                </>
                            ) : null}
                            {showActionModal === 'delete' ? (
                                <MultiContentDossierDelete
                                    kedo={kedo}
                                    showModal={true}
                                    size={'sm'}
                                    onCancel={() => {
                                        setShowActionModal('')
                                    }}
                                    onClose={() => {
                                        setShowActionModal('')
                                        onDeleteSuccess()
                                    }}
                                    items={selected}
                                    mode={mode}
                                />
                            ) : null}
                            {showActionModal === 'all-export' ? (
                                <ContentDossierExport
                                    showModal={showActionModal === 'all-export'}
                                    defDossierId={defDossierId}
                                    disablePDF={true}
                                    disableWord={true}
                                    filter={getSearchFilter}
                                    size={'sm'}
                                    kedo={kedo}
                                    onCancel={() => {
                                        setShowActionModal('')
                                    }}
                                    onClose={() => {
                                        setShowActionModal('')
                                        fetchDossierResults()
                                    }}
                                    selected={[]}
                                    mode={
                                        mode === 'embeddedList' ||
                                        mode === 'block'
                                            ? 'all'
                                            : null
                                    }
                                />
                            ) : null}
                            {showActionModal === 'export' ? (
                                <ContentDossierExport
                                    showModal={showActionModal === 'export'}
                                    defDossierId={defDossierId}
                                    filter={getSearchFilter}
                                    size={'sm'}
                                    kedo={kedo}
                                    onCancel={() => {
                                        setShowActionModal('')
                                    }}
                                    onClose={() => {
                                        setShowActionModal('')
                                        fetchDossierResults()
                                    }}
                                    selected={selected}
                                    mode={
                                        mode === 'embeddedList' ||
                                        mode === 'block'
                                            ? 'all'
                                            : null
                                    }
                                />
                            ) : null}
                            {showActionModal === 'archive' ||
                            showActionModal === 'dearchive' ? (
                                <MultiContentDossierArchive
                                    kedo={kedo}
                                    showModal={true}
                                    onClose={() => {
                                        setShowActionModal('')
                                        fetchDossierResults()
                                    }}
                                    archived={showActionModal === 'archive'}
                                    checked={selected}
                                    mode={mode}
                                />
                            ) : null}
                            {showActionModal === 'merge' ? (
                                <MultiContentDossierMerge
                                    props={props}
                                    kedo={kedo}
                                    defDossierId={defDossierId}
                                    closeModal={() => {
                                        setShowActionModal('')
                                        fetchDossierResults()
                                    }}
                                    idDossiers={selectedDossiers.map(
                                        (item) => item.id
                                    )}
                                />
                            ) : null}
                            {showActionModal === 'subscribe' ||
                            showActionModal === 'stopsubscription' ? (
                                <MultiContentDossierSubscribe
                                    kedo={kedo}
                                    showModal={true}
                                    onClose={() => {
                                        setShowActionModal('')
                                        fetchDossierResults()
                                    }}
                                    subscriptions={subscriptions}
                                    items={selected}
                                    showActionModal={showActionModal}
                                    mode={mode}
                                />
                            ) : null}
                            {showActionModal === 'favorite' ||
                            showActionModal === 'removefavorite' ? (
                                <MultiContentDossierFavorite
                                    kedo={kedo}
                                    showModal={true}
                                    showActionModal={showActionModal}
                                    onClose={() => {
                                        setShowActionModal('')
                                        fetchDossierResults()
                                    }}
                                    favorites={favorites}
                                    items={selected}
                                />
                            ) : null}
                            {showActionModal === 'massupdate' ? (
                                <MultiContentDossierMassUpdate
                                    kedo={kedo}
                                    showModal={true}
                                    onClose={() => {
                                        setShowActionModal('')
                                        fetchDossierResults()
                                    }}
                                    mode={mode}
                                    selectedDossierIds={
                                        selectedDossiers?.length > 0
                                            ? selectedDossiers?.map(
                                                  (ids) => ids.id
                                              )
                                            : dossiers?.map((ids) => ids.id)
                                    }
                                    contents={contents}
                                />
                            ) : null}
                        </div>
                    </div>
                </th>
            </tr>
            {!dossiers || dossiers.length <= 0 ? (
                <Alert variant={'info'} style={{ margin: '0 17px' }}>
                    <FontAwesomeIcon icon={faExclamationCircle} />
                    &nbsp; {kedo.t('No items found')}
                </Alert>
            ) : (
                <tr>
                    <th style={{ width: '3%' }}>
                        {dossiers.length > 0 ? (
                            <input
                                checked={selected.length === dossiers.length}
                                onChange={checkAll}
                                type="checkbox"
                                style={{ marginRight: '0px' }}
                            />
                        ) : null}
                    </th>
                    {props.showEmail === true ? <th /> : null}
                    <th style={{ textAlign: 'center' }}>
                        <FontAwesomeIcon icon={faBell} />
                    </th>
                    {listItems.map((listItem) => {
                        const isSortableField = isSortable(listItem)

                        //Item is not sortable
                        if (!isSortableField) {
                            return (
                                <th data-ddiid={listItem.id} key={listItem.id}>
                                    {kedo
                                        .env()
                                        .translateItem(listItem, 'displayitem')}
                                    <AdminQuickEditMenu
                                        className={'list-admin-edit-buttons'}
                                        kedo={kedo}
                                        item={listItem}
                                    />
                                </th>
                            )
                        }

                        let sortItem = null
                        if (
                            listItem.def_dossier_def_field &&
                            listItem.def_dossier_def_field.id
                        ) {
                            sortItem = sort.find(
                                (itList) =>
                                    itList.item.def_dossier_def_field &&
                                    itList.item.def_dossier_def_field.id ===
                                        listItem.def_dossier_def_field.id
                            )
                        } else if (
                            listItem.settings &&
                            listItem.settings.dossier_data
                        ) {
                            sortItem = sort.find(
                                (itList) =>
                                    itList.item.settings &&
                                    listItem.settings.dossier_data ===
                                        itList.item.settings.dossier_data
                            )
                        }

                        //Item is sortable
                        return (
                            <th data-ddiid={listItem.id} key={listItem.id}>
                                <span
                                    onClick={(e) =>
                                        applySort(
                                            e,
                                            listItem,
                                            sortItem && sortItem.order === 'ASC'
                                                ? 'DESC'
                                                : 'ASC'
                                        )
                                    }
                                    style={{ cursor: 'pointer' }}
                                >
                                    {kedo.translateItem(
                                        listItem,
                                        'displayitem'
                                    )}{' '}
                                    &nbsp;
                                    {sortItem ? (
                                        <FontAwesomeIcon
                                            icon={
                                                sortItem.order === 'ASC'
                                                    ? faSortUp
                                                    : faSortDown
                                            }
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            className={'text-muted'}
                                            icon={faSort}
                                        />
                                    )}
                                    &nbsp;&nbsp;
                                </span>
                                {sortItem ? (
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        onClick={(e) => clearSort(e, listItem)}
                                        size={'sm'}
                                        style={{ cursor: 'pointer' }}
                                    />
                                ) : null}
                                <AdminQuickEditMenu
                                    className={'list-admin-edit-buttons'}
                                    kedo={kedo}
                                    item={listItem}
                                />
                            </th>
                        )
                    })}
                    {actionBtns.length > 0 ? (
                        <th>{kedo.t('Actions')}</th>
                    ) : null}
                    <th />
                    <th />
                </tr>
            )}
        </thead>
    )
}

export default ContentDossierIndexHeader
