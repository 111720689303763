import React, { Component } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import LoadingDefault from '../Elements/Loading/LoadingDefault'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-duotone-svg-icons/faTrashAlt'

class ConfirmRedirectWebpageDialog extends Component {
    constructor(props) {
        super(props)

        this.state = {
            submitting: false,
        }
    }

    getForm() {
        return (
            <Modal
                centered
                show={true}
                variant={'warning'}
                onHide={() => this.props.cancel()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.kedo.t('Confirm')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>{this.props.kedo.t('confirm_redirect_changes')}</Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.accept} variant="primary">
                        {this.props.kedo.t('Continue')}
                    </Button>{' '}
                    &nbsp;
                    <Button
                        onClick={() => this.props.cancel()}
                        variant="secondary"
                    >
                        {this.props.kedo.t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        return this.getForm()
    }
}

export default ConfirmRedirectWebpageDialog
